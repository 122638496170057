import React, { Component, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
//import LoadingBar from "react-top-loading-bar";
// import PhoneInput from 'react-phone-input-2';
//import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';


import './landingpage.css';


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectAdmin: false,
      redirectAdministrator: false,
      redirectVisitor: false,
      QuestionTopicsList: false,


      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,

      
      kdidentificationhidediv:false,
      kdprofilinghidediv:false,
      kdinteracthidediv:false,


      loginModal: false,
      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: '',
      resercher_name: '',

      // Manager info
      manager_id: '',
      manager_name: '',

      // Employee info
      employee_id: '',
      employee_name: '',

      // For validations
      UsernameErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      wrngUsPsErr: '',

      clientUsernameErr: '',
      clientPasswordErr: '',

      administratorUsernameErr: '',
      administratorPasswordErr: '',

      visitorUsernameErr: '',
      visitorPasswordErr: '',

      kolProfileUsernameErr: '',
      kolProfilePasswordErr: '',
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      loginData: {
        client_id: '',
        password: '',
        role: '',
        // loginType: 'team',
      },

      ClientLoginData: {
        login_id: '',
        password: '',
        login_type: 'client',
      },
      teamLoginIdForTerms: '',
      //Administrator
      AdministratorLoginData: {
        login_id: '',
        password: '',
        login_type: 'administrator',
      },
      //visitor
      VisitorLoginData: {
        login_id: '',
        password: '',
        login_type: 'visitor',
      },

      KolProfileLoginData: {
        username: '',
        password: '',
        // kol_login_role: '',
      },
      kolLoginIdForTerms: '',

      contact_us: {
        first_name: '',
        last_name: '',
        organization: '',
        email_id: '',
        phone_no: '',
        country: '',
        learn_qpharma: '',
        representative_contact_me: '',
        qpharmas_products: '',
        technical_support_issue: '',
        need_of_assistance: '',
        receive_qpharma_news: '',
        describe_issue_question: '',
      },
      cuFirstNameErr: '',
      cuLastNameErr: '',
      cuOrganizaErr: '',
      cuEmailErr: '',
      cuPhoneErr: '',
      cuCountryErr: '',

      new_guru: {
        first_name: '',
        last_name: '',
        mobile_no: '',
        email_id: '',
        speciality: '',
        sub_speciality: '',
        procedure_type: '',
        country: '',
        state: '',
        current_place_of_work: '',
        department: '',
        linkedin_link: '',
        additional_info: '',
      },
      ngFirstNameErr: '',
      ngLastNameErr: '',
      ngEmailErr: '',
      cuPhoneErr: '',
      ngCountryErr: '',
      ngStateErr: '',

      new_client: {
        first_name: '',
        last_name: '',
        email_id: '',
        mobile_no: '',
        msg: '',
      },
      ncFirstNameErr: '',
      ncLastNameErr: '',
      ncEmailErr: '',
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: '',
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: '',
        role: '',
      }
    };

    
    //this.login = this.login.bind(this);
    
   // this.setState({redirect: true})


  }

  
  componentDidMount = async () => {
    console.log('in componentDidMount');
    
    
    

    // this.setState({
    // 	ShowBar: true,
    // 	progressBar: true,
      
    // })

  }

  
  
  // // contact us 
  // toggleContactUsModal() {
  //   this.setState({
  //     ContactUsModal: !this.state.ContactUsModal,
  //     cuFirstNameErr: '',
  //     cuLastNameErr: '',
  //     cuOrganizaErr: '',
  //     cuEmailErr: '',
  //     // cuPhoneErr: '',
  //     cuCountryErr: '',
  //   });
  // }
  // validateContactUs = () => {
  //   let cuFirstNameErr = '';
  //   let cuLastNameErr = '';
  //   let cuOrganizaErr = '';
  //   let cuEmailErr = '';
  //   // let cuPhoneErr= '';
  //   let cuCountryErr = '';

  //   if (!this.state.contact_us.first_name) {
  //     cuFirstNameErr = 'Please Enter First Name';
  //   }

  //   if (!this.state.contact_us.last_name) {
  //     cuLastNameErr = 'Please Enter Last Name';
  //   }
  //   if (!this.state.contact_us.organization) {
  //     cuOrganizaErr = 'Please Enter Organization';
  //   }
  //   if (!this.state.contact_us.email_id) {
  //     cuEmailErr = 'Please Enter Email';
  //   }
  //   else if (typeof this.state.contact_us.email_id !== "undefined") {
  //     let lastAtPos = this.state.contact_us.email_id.lastIndexOf('@');
  //     let lastDotPos = this.state.contact_us.email_id.lastIndexOf('.');

  //     if (!(lastAtPos < lastDotPos && lastAtPos > 0)) {
  //       //   formIsValid = false;
  //       cuEmailErr = "Email is not valid";
  //       if (cuEmailErr) {
  //         this.setState({ cuEmailErr })
  //       }
  //     } else {
  //       this.setState({ cuEmailErr: '' })
  //     }
  //   }
  //   else {
  //     this.setState({ cuEmailErr: '' })
  //   }
  //   // if(!this.state.contact_us.phone_no)  {
  //   //     cuPhoneErr = 'Please Enter Phone Number';
  //   // }
  //   if (!this.state.contact_us.country) {
  //     cuCountryErr = 'Please Selct Country';
  //   }


  //   if (cuFirstNameErr || cuLastNameErr || cuOrganizaErr || cuEmailErr || cuCountryErr) {
  //     this.setState({ cuFirstNameErr, cuLastNameErr, cuOrganizaErr, cuEmailErr, cuCountryErr });
  //     return false;
  //   }
  //   return true;
  // }
  
 

  //login = toggle validate and login
  toggleLoginModal() {
    //console.log("loginclicked")
    this.setState({
      loginModal: !this.state.loginModal,
      UsernameErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      MySProfilePropover: false,
    });
  }
  validate = () => {
    let UsernameErr = '';
    let PasswordErr = '';
    let SelectRoleErr = '';

    if (!this.state.loginData.client_id) {
      UsernameErr = 'Please Enter User Id';
    }
    if (!this.state.loginData.password) {
      PasswordErr = 'Please Enter Password';
    }
    // if (!this.state.loginData.role) {
    //   SelectRoleErr = 'Please Select Role';
    // }

    // if (UsernameErr || PasswordErr || SelectRoleErr) {
    //   this.setState({ UsernameErr, PasswordErr, SelectRoleErr });
    //   return false;
    // }
    if (UsernameErr || PasswordErr ) {
      this.setState({ UsernameErr, PasswordErr });
      return false;
    }
    return true;
  }



  login() {



    const clid=this.state.loginData.client_id;

   
      const isValid = this.validate();
      if (isValid) {
        this.setState({
          ShowBar: true,
        //  progressBar: true,
        })
        console.log("this.state.loginData.client_id", this.state.loginData.client_id)
        console.log("this.state.loginData.password", this.state.loginData.password)
        //console.log("this.state.loginData.role", '')
        
        //  alert(this.state.loginData.client_id);
        //  alert(this.state.loginData.password);
        // // alert(this.state.loginData.role);
        
  
        const url = global.websiteUrl+'/service/User/login';
       //const url = 'http://localhost/booklet/service/User/login';
        
        let formData = new FormData();
        formData.append('login_id', this.state.loginData.client_id);
        formData.append('password', this.state.loginData.password);
        //formData.append('role', this.state.loginData.role);
        formData.append('login_type', "team");
  
  
        const config = {
          //   headers: { 'content-type': 'multipart/form-data' }
        }
  
        axios.post(url, formData, config)
          .then(response => {
            console.log(response);
  
  
            // alert(response.data.status);
  
            var lgdata = response.data;
            console.log("lgdata", JSON.stringify(lgdata));
            var lgstatus = response.data.status;
            console.log("lgstatus", JSON.stringify(lgstatus));
            console.log("response message", response.message)
            console.log("response message2", response.data.message)
            var empInfo = response.data.Team_User_Info
            console.log("response empInfo", empInfo)
           

            //alert(response.data.status);
            if (response.data.status == true) {
              empInfo.map(async (data) => {
                //console.log("data.role_type", data.role_type)
             
                  this.setState({
                    QuestionTopicsList: true,
                  })
                  sessionStorage.setItem('user_email', data.email_id)
                  sessionStorage.setItem('user_id', data.emp_id)
                  sessionStorage.setItem('user_name', data.emp_name)
                              
              })
            }
  
            else if (response.data.status == false) {
               
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Wrong Username or Password!',
                // showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
              // }
            }
          })
          .catch(error => {
            console.log(error);
          });
  
        this.setState({
          loginModal: false, loginData: {
            client_id: '',
            password: '',
            role: '',
          }
        });

       
        //clear form
        //this.setState(initialState);
      }
    
  
  
      
      
      this.setState({
        ShowBar: false,
      })
    
  }



  // Visitorlogin() {
  //   const isValid = this.validate();
  //   if (isValid) {
  //     this.setState({
  //       ShowBar: true,
  //     })
  //     console.log("this.state.loginData.visitor_id", this.state.loginData.client_id)
  //     console.log("this.state.loginData.password", this.state.loginData.password)

  //     const url = global.websiteUrl+'/service/User/login';
  //     let formData = new FormData();
  //     formData.append('login_id', this.state.loginData.client_id);
  //     formData.append('password', this.state.loginData.password);
  //     formData.append('login_type', "visitor");

  //     const config = {
  //       //   headers: { 'content-type': 'multipart/form-data' }
  //     }
  //     axios.post(url, formData, config)
  //       .then(response => {
  //         console.log(response);
  //         var lgdata = response.data.Client_Info;
  //         console.log("lgdata===========", JSON.stringify(lgdata));
  //         var lgstatus = response.data.status;
  //         console.log("lgstatus", JSON.stringify(lgstatus));
  //         console.log("response message2", response.data.message)
  //         this.setState({
  //           ShowBar: false,
  //         })
  //         if (lgstatus == true) {
  //           this.setState({ redirectVisitorDashboard: true })
  //           lgdata.map((data) => {
  //             sessionStorage.setItem('visitor_email', data.email_id)
  //             sessionStorage.setItem('visitor_id', data.client_id)
  //             sessionStorage.setItem('visitor_name', data.client_name)
  //           })
  //         }
  //         else if (lgstatus == false) {
  //           // alert("Wrong Username Or Password")
  //           Swal.fire({
  //             icon: 'error',
  //             title: 'Oops...',
  //             text: 'Wrong Username or Password!',
  //             // showCancelButton: true,
  //             confirmButtonColor: '#d33',
  //             cancelButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         }
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });

  //     this.setState({
  //       loginModal: false,
  //       loginData: {
  //         client_id: '',
  //         password: '',
  //       }
  //     });
  //   }
  // }




  // Clientlogin() { 
  //   // const isValid = this.validateClient();
  //   const isValid = this.validate();
  //    if (isValid) {
  //      this.setState({
  //        ShowBar: true,
  //      })
  //      console.log("this.state.loginData.client_id", this.state.loginData.client_id)
  //      console.log("this.state.loginData.password", this.state.loginData.password)
 
       
 
  //      const url = global.websiteUrl+'/service/User/login';
  //      //const url = 'http://localhost/Cetas/service/User/login';
 
  //      let formData = new FormData();
  //      formData.append('login_id', this.state.loginData.client_id);
  //      formData.append('password', this.state.loginData.password);
  //      formData.append('login_type', "client");
 
 
  //      const config = {
  //        //   headers: { 'content-type': 'multipart/form-data' }
  //      }
  //      axios.post(url, formData, config)
  //        .then(response => {
  //          console.log(response);
  //          var lgdata = response.data.Client_Info;
  //          console.log("lgdata===========", JSON.stringify(lgdata));
  //          var lgstatus = response.data.status;
  //          console.log("lgstatus", JSON.stringify(lgstatus));
  //          console.log("response message2", response.data.message)
  //          this.setState({
  //            ShowBar: false,
  //          })
  //          if (lgstatus == true) {
              
  //           var login_type ='';
           
  //           // alert(data.login_type);
  //           //this.setState({ redirectClientDashboard: true })
  //            lgdata.map((data) => {
              
  //             login_type = data.login_type;

  //              sessionStorage.setItem('client_email', data.email_id)
  //              sessionStorage.setItem('client_id', data.client_id)
  //              sessionStorage.setItem('client_name', data.client_name)

  //              sessionStorage.setItem('administrator_email', data.email_id)
  //              sessionStorage.setItem('administrator_id', data.client_id)
  //              sessionStorage.setItem('administrator_name', data.client_name)
               
  //            })

  //   //         alert(login_type);
  //            if(login_type == "administrator"  )
  //            {
            
  //             this.setState({ redirectAdministratorDashboard: true })
              
  //            }
  //            else if(login_type == "client"  )
  //            {

  //            this.setState({ redirectClientDashboard: true })
  //             // window.open('https://app.medtech-gurus.com')

  //            }           

  //          }
  //          else if (lgstatus == false) {
  //            // alert("Wrong Username Or Password")
  //            Swal.fire({
  //              icon: 'error',
  //              title: 'Oops...',
  //              text: 'Wrong Username or Password!',
  //              // showCancelButton: true,
  //              confirmButtonColor: '#d33',
  //              cancelButtonColor: '#d33',
  //              confirmButtonText: 'OK'
  //            })
  //          }
  //        })
  //        .catch(error => {
  //          console.log(error);
  //        });
 
  //      this.setState({
  //        loginModal: false,
  //        loginData: {
  //          client_id: '',
  //          password: '',
  //        }
  //      });
  //    }
  //  }
 


  // KolProfileLogin() {
  //   const isValid = this.validate();

  //   if (isValid) {
  //     // if( this.state.KolProfileLoginData.kol_login_role == "kol")
  //     // {
  //     this.setState({
  //       ShowBar: true,
  //     })
  //     const url = global.websiteUrl+'/service/KOL_Dashboard/KOL_Login';
  //     let formData = new FormData();
  //     formData.append('kol_id', this.state.loginData.client_id);
  //     formData.append('password', this.state.loginData.password);

  //     sessionStorage.setItem('kol_login_time_password', this.state.loginData.password)

  //     const config = {
  //       //   headers: { 'content-type': 'multipart/form-data' }
  //     }
  //     axios.post(url, formData, config)
  //       .then(response => {
  //         console.log(response);
  //         var lgdata = response.data;
  //         console.log("lgdata", JSON.stringify(lgdata));
  //         var lgstatus = response.data.status;
  //         console.log("lgstatus", JSON.stringify(lgstatus));
  //         console.log("response message2", response.data.message)
  //         console.log("kol-id", response.data.kol_Info)
  //         var kolInfo = response.data.kol_Info;
  //         this.setState({
  //           ShowBar: false,
  //         })
  //         if (lgstatus == true) {
  //           this.setState({ redirectKolProfile: true })
  //           kolInfo.map((data) => {
  //             console.log("research_total_survey===============", data.research_total_survey)
  //             sessionStorage.setItem('research_total_survey', data.research_total_survey)
  //             sessionStorage.setItem('kol_uniqu_id', data.kol_unique_id)
  //             sessionStorage.setItem('kol_name', data.name)
  //           })
  //         }
  //         else if (lgstatus == false) {
  //           if (response.data.message == "Terms and conditions not agreed") {
  //             this.toggleTermsAndConditionModal();
  //           }
  //           // alert("Wrong Username Or Password")
  //           else {
  //             Swal.fire({
  //               icon: 'error',
  //               title: 'Oops...',
  //               // text: 'Wrong Username or Password!',
  //               text: response.data.message,
  //               // showCancelButton: true,
  //               confirmButtonColor: '#d33',
  //               cancelButtonColor: '#d33',
  //               confirmButtonText: 'OK'
  //             })
  //           }
  //         }
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });

  //     this.setState({
  //       loginModal: false, loginData: {
  //         client_id: '',
  //         password: '',
  //       }
  //     });
  //   }
  //   // else{
  //   //     Swal.fire({
  //   //         icon: 'error',
  //   //         title: 'Oops...',
  //   //         text: 'Sorry...Doctor Login is not available',
  //   //         // showCancelButton: true,
  //   //         confirmButtonColor: '#d33',
  //   //         cancelButtonColor: '#d33', 
  //   //         confirmButtonText: 'OK'
  //   //     }) 
  //   // }
  //   // }

  // }





  

  // Administratorlogin() {
  //   const isValid = this.validate();
  //   if (isValid) {
  //     this.setState({
  //       ShowBar: true,
  //     })
  //     console.log("this.state.loginData.administrator_id", this.state.loginData.client_id)
  //     console.log("this.state.loginData.password", this.state.loginData.password)

  //     const url = global.websiteUrl+'/service/User/login';
  //     let formData = new FormData();
  //     formData.append('login_id', this.state.loginData.client_id);
  //     formData.append('password', this.state.loginData.password);
  //     formData.append('login_type', "administrator");

  //     const config = {
  //       //   headers: { 'content-type': 'multipart/form-data' }
  //     }
  //     axios.post(url, formData, config)
  //       .then(response => {
  //         console.log(response);
  //         var lgdata = response.data.Client_Info;
  //         console.log("lgdata===========", JSON.stringify(lgdata));
  //         var lgstatus = response.data.status;
  //         console.log("lgstatus", JSON.stringify(lgstatus));
  //         console.log("response message2", response.data.message)
  //         this.setState({
  //           ShowBar: false,
  //         })
  //         if (lgstatus == true) {
  //           this.setState({ redirectAdministratorDashboard: true })
  //           lgdata.map((data) => {
  //             sessionStorage.setItem('administrator_email', data.email_id)
  //             sessionStorage.setItem('administrator_id', data.client_id)
  //             sessionStorage.setItem('administrator_name', data.client_name)
  //           })
  //         }
  //         else if (lgstatus == false) {
  //           // alert("Wrong Username Or Password")
  //           Swal.fire({
  //             icon: 'error',
  //             title: 'Oops...',
  //             text: 'Wrong Username or Password!',
  //             // showCancelButton: true,
  //             confirmButtonColor: '#d33',
  //             cancelButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         }
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });

  //     this.setState({
  //       loginModal: false,
  //       loginData: {
  //         client_id: '',
  //         password: '',
  //       }
  //     });
  //   }
  // }





  



  
  makespaceondivkdidentification()
  {
    
    this.setState({

      hidedivkdprofiling:false,
      hidedivkdinteract:false,
      hidedivkdidentification:true,
      


    });

  }

  makespaceondivkdprofiling()
  {
    
    this.setState({

      hidedivkdidentification:false,
      hidedivkdinteract:false,
      hidedivkdprofiling:true,

    });

  }


  makespaceondivkdinteract()
  {
    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:true,

    });

  }

  

  makespaceondivhome()
  {    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:false,

    });
  }

  
  render() {

   if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
  }


    if (this.state.redirectAdmin) {
      return (<Redirect to={'/AdminDashboard'} />)
    }

    if (this.state.QuestionTopicsList) {
      return (<Redirect to={{
        pathname: '/QuestionTopicsList',
        state: { RID: this.state.RID, resercher_name: this.state.resercher_name }
      }} />)
    }

    if (this.state.redirectManager) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name} 
      }} />)
    }

    if (this.state.redirectEmployee) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
        //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name} 
      }} />)
    }

    if (this.state.redirectClientDashboard) {
      return (<Redirect to={{
        pathname: '/ClientViewProject',
      }} />)
    }

    if (this.state.redirectAdministratorDashboard) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }

    if (this.state.redirectVisitorDashboard) {
      return (<Redirect to={{
        pathname: '/VisitorDashboard',
      }} />)
    }

    if (this.state.redirectKolProfile) {
      return (<Redirect to={{
        pathname: '/KolProfileDashboard',
      }} />)
    }

    if (this.state.redirectTermsandPrivacy) {
      return (<Redirect to={{
        pathname: '/TermsPrivacyPolicy',
      }} />)
    }

    // // Speciality Type options 
    // let speciality_type = this.state.SpecialityData;
    // let OptionsSpeciality = speciality_type.map((speciality_type) =>
    //   <option key={speciality_type.id} value={speciality_type.id}>
    //     {speciality_type.speciality_type}</option>
    // );

    // // Procedure List
    // let procedure_list = this.state.ProcedureData;
    // let OptionProcedure = procedure_list.map((procedure_list) =>
    //   <option key={procedure_list.name} value={procedure_list.name}>
    //     {procedure_list.procedure_type}</option>
    // );

    // // Sub Speciality List
    // let subSpeciality_list = this.state.SubSpecialityData;
    // let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
    //   <option key={subSpeciality_list.name} value={subSpeciality_list.name}>
    //     {subSpeciality_list.sub_speciality_type}</option>
    // );

    // // Country List
    // let Country_list = this.state.CountryListData;
    // let OptionsCountry = Country_list.map((Country_list) =>
    //   <option key={Country_list.id} value={Country_list.id}>
    //     {Country_list.country_name}</option>
    // );

    // // State List
    // let state_list = this.state.StateData;
    // let OptionsState = state_list.map((state_list) =>
    //   <option key={state_list.name} value={state_list.name}>
    //     {state_list.state_name}</option>
    // );

    return (

        
      <body id="landing-body">


        <div>
          {this.state.ShowBar ? <Modal isOpen={true}  centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' style={{ textAlign:'center'}} />
          </Modal> : null}
        </div>

        {/* {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}

        {/* Call the function to add a library */}
  {/* {AddLibrary('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')} */}
{/* 
  {AddLibrary('./js/jquery.min.js')}
   {AddLibrary('./js/bootstrap.bundle.min.js')}
  {AddLibrary('./js/owl.carousel.min.js')} */}
  {AddLibrary('./js/app.js')}
  


        
       
    {/* <header className="">style={{backgroundColor:'White'}}
     */}
    

    
    {/* <div className="top-nav" id="home">
        <div className="container8" >
            <div className="row1">
                <div className="email1">
                    <p> <i className='bx bxs-envelope'></i> info@cetas-healthcare.com</p>
                    <p> <i className='bx bxs-phone-call'></i> +31-685281053</p>
                </div>
                <div className='social'>
                <div className="social-icons">
                    <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                    <a href="https://twitter.com/cetashealthcare" target="_blank"><i className='bx bxl-twitter'></i></a>
                    <a href="https://www.youtube.com/channel/UCzJPjYnPXRZjWs-H1IqvMFQ" target="_blank"><i className='bx bxl-youtube'></i></a>
                </div>
                </div>
            </div>
        </div>
    </div> */}

    
    <nav className="navbar navbar-expand-lg navbar-light sticky-top" >
      <div className="content" style={{width:'100%'}} > 
      {/* ,boxShadow:'inset 0 0 2px #ccc' */}
        <div className='container2'  style={{ marginTop:'15px'}}>
            <a className="navbar-brand" href="https://www.cetas-healthcare.com/"><img src={require('./Assets/Images/cetas_healthcare_logo.svg')} width="60" height="60"></img></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav" style={{marginTop:'-63px'}} >
                <ul className="navbar-nav ms-auto" >
                </ul>
               
                    <a href="#" data-bs-toggle="modal" className="btn btn-brand ms-lg-3" style={{ cursor: 'pointer',paddingLeft:'30px',paddingRight:'30px' }} onClick={this.toggleLoginModal.bind(this)}>Log In</a>
            </div>
            </div>
        </div>
        </nav>



      <div className='container'  >
        <div class="row">
        
                {/* <h1>Question Bank</h1> */}
        
                <img src={require('./Assets/Images/project3.jpg')}/> 
        
        </div>
        </div>




    <footer>
        <div className="footer-top text-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center" style={{height:'20px'}}>
                        {/* <div className="col-auto social-icons">
                            <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                            <a href="https://twitter.com/cetashealthcare" target="_blank"><i className='bx bxl-twitter'></i></a>
                            <a href="https://www.youtube.com/channel/UCzJPjYnPXRZjWs-H1IqvMFQ" target="_blank"><i className='bx bxl-youtube'></i></a>
                        </div>
                        <div className="col-auto conditions-section">
                            <a href="https://www.cetas-healthcare.com/privacy_policy/" target="_blank" rel="opener">Privacy</a>
                            <a href="https://www.cetas-healthcare.com/terms-of-use/" target="_blank" rel="opener">Terms</a>
                            <a href="https://www.cetas-healthcare.com/cookie-notice/" target="_blank" rel="opener">Cookie Notice</a>
                        </div> */}
                        <p className="mb-0">Copyright Cetas 2022. All rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>


  



    <Modal isOpen={this.state.loginModal} toggle={this.toggleLoginModal.bind(this)} centered>
            <ModalHeader toggle={this.toggleLoginModal.bind(this)}
              cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
              style={{ borderBottom: '0px', height:'10px' }}>
              {/* <h1 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Login</h1>
              <div style={{ justifyContent: 'center', marginTop: '30px' }}>
              <img id="lglogos" src={require('./Assets/Images/cetas_healthcare_logo.svg')} alt="Cetas"></img>
    
              </div> */}
            </ModalHeader>

            
            <ModalBody >


            <form className="text-center " >
                                    <div>
                                        <img id="lglogos" src={require('./Assets/Images/cetas_healthcare_logo.svg')} alt="Cetas"></img>
                                    </div>

                                    <div className="col-12 text-center"  >
                                      <label for="" className="form-label"></label>
                                      {/* <input type="text" className="form-control" placeholder="Enter Username" id="" aria-describedby=""></input> */}
                                       <Input id="Client_Id" placeholder="User Id"
                                        style={{ width: '60%', height: '50px' , marginTop:'10px' , marginLeft:'20%',border:'solid',borderColor:'#E8E9E8',borderWidth:'.5px'}}
                                        type="email"
                                        value={this.state.loginData.client_id} onChange={(e) => {
                                          let { loginData } = this.state;
                                          loginData.client_id = e.target.value;
                                          this.setState({ loginData, teamLoginIdForTerms: e.target.value });
                                        }}
                                      />
                                    </div>

                                     
                                    <div className="" style={{ marginTop: '10px', color:'red'}}> {this.state.UsernameErr}</div>

                                    <div className="col-lg-12">
                                        {/* <input type="password" className="form-control" placeholder="Enter Password" id="" aria-describedby=""></input> */}
                                        <Input id="password" placeholder="Password"
                                          style={{ width: '60%', height: '50px' , marginTop:'10px' , marginLeft:'20%',border:'solid',borderColor:'#E8E9E8',borderWidth:'.5px' }}
                                          type="password"
                                          value={this.state.loginData.password} onChange={(e) => {
                                            let { loginData } = this.state;
                                            loginData.password = e.target.value;
                                            this.setState({ loginData });
                                          }}
                                        />
                                    </div>
                                    <div className="" style={{ marginTop: '10px', color:'red'}}>{this.state.PasswordErr}</div>
{/* 
                                    <div className="col-12" style={{marginTop:'32px'}}>
                                    
                                                                                <Input type="select" id="roleSelect"
                                                    style={{ width: '60%', height: '50px', marginTop: '3%' , marginLeft:'20%' }}
                                                    value={this.state.loginData.role}
                                                    // value2={this.state.newNewsData.category_name}
                                                    onChange={(e) => {
                                                      let { loginData } = this.state;
                                                      loginData.role = e.target.value;
                                                      console.log("employee role", e.target.value);
                                                      // newNewsData.category_name = e.target.value2;
                                                      // console.log(e.target.value2);
                                                      this.setState({ loginData });
                                                    }}>
                                                    <option value="0" >--Select Role--</option>
                                                    <option value="admin" >Admin</option>
                                                    <option value="researcher" >Researcher</option>
                                                    <option value="project manager" >Project Manager</option>
                                                    <option value="employee" >Employee</option>
                                                    {/* {CategoryItems}    */}
                                                  {/* </Input>
                                    </div>
                                    <div className="" style={{ marginTop: '10px', color:'red'}}>{this.state.SelectRoleErr}</div> */}

                                   
                                </form>


</ModalBody>              
            <ModalFooter style={{ borderTop: '0px' }}>
          
            <div className="col-12">
                                        {/* <button type="submit" className="btn btn-brand btn-block w-100">Login</button> */}
                                       <button type="submit" className="btn btn-brand btn-block" style={{ marginLeft:'40%',marginTop: '-20px',marginBottom:'50px',width:'30%' }}
                                          onClick={this.login.bind(this)}>
                                          Login
                                        </button>
                                    </div>
            </ModalFooter>
          </Modal>



    
    

          <div className="modal fade" id="exampleModallogin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
                <div className="modal-body p-0">
                    <div className="container-fluid">
                        <div className="row gy-12">
                            <div className="col-lg-12">
                                <form className="p-lg-5 col-12 row g-3">
                                    <div>
                                        <img id="lglogos" src={require('./Assets/Images/cetas-logo.png')} alt="Cetas"></img>
                                    </div>
                                    <div className="col-lg-12">
                                        <label for="" className="form-label"></label>
                                        {/* <input type="text" className="form-control" placeholder="Enter Username" id="" aria-describedby=""></input> */}
                                        <Input id="Client_Id" placeholder="Team Id"
                                        style={{ width: '80%', height: '50px', }}
                                        type="email"
                                        value={this.state.loginData.client_id} onChange={(e) => {
                                            let { loginData } = this.state;
                                            loginData.client_id = e.target.value;
                                            this.setState({ loginData, teamLoginIdForTerms: e.target.value });
                                        }}
                                    />
                                    </div>
                                    
                                <div className="centerErrLogin">{this.state.UsernameErr}</div>

                                    <div className="col-lg-12">
                                        {/* <input type="password" className="form-control" placeholder="Enter Password" id="" aria-describedby=""></input> */}
                                        <Input id="password" placeholder="Password"
                                        style={{ width: '80%', height: '50px', }}
                                        type="password"
                                        value={this.state.loginData.password} onChange={(e) => {
                                            let { loginData } = this.state;
                                            loginData.password = e.target.value;
                                            this.setState({ loginData });
                                        }}
                                    />
                                    </div>
                                    <div className="centerErrLoginPass">{this.state.PasswordErr}</div>

                                    <div className="col-12">
                                        {/* <select className="form-control">
                                            <option>--Select Role--</option>
                                            <option>Admin</option>
                                            <option>Client</option>
                                            <option>Employee</option>
                                            <option>Researcher</option>
                                            <option>Project Manager</option>
                                        </select> */}
                                        <Input type="select" id="roleSelect"
                                        style={{ width: '80%', height: '50px', marginTop: '3%' }}
                                        value={this.state.loginData.role}
                                        // value2={this.state.newNewsData.category_name}
                                        onChange={(e) => {
                                            let { loginData } = this.state;
                                            loginData.role = e.target.value;
                                            console.log("employee role", e.target.value);
                                            // newNewsData.category_name = e.target.value2;
                                            // console.log(e.target.value2);
                                            this.setState({ loginData });
                                        }}>
                                        <option value="0" >--Select Role--</option>
                                        <option value="admin" >Admin</option>
                                        <option value="researcher" >Researcher</option>
                                        <option value="project manager" >Project Manager</option>
                                        <option value="employee" >Employee</option>
                                        {/* {CategoryItems}    */}
                                    </Input>
                                    </div>
                                    <div className="centerErrLogin" >{this.state.SelectRoleErr}</div>

                                </form>

                                
                                <div className="col-12">
                                        <button type="submit" className="btn btn-brand btn-block w-100">Login</button>
                                        {/* <button type="submit" class="btn btn-danger btn-labeled" onClick={this.login.bind(this)}>Login</button> */}
                                    </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>



      {/* </header> */}
 
      </body>
    );
  }
}

export default Login;