import React,{Component} from 'react';
import { Redirect } from 'react-router-dom';
import logo from '../Assets/Images/cetas_healthcare_logo.svg';
import cartimage from '../Assets/Images/cart.png';
class UserHader extends Component
{
    constructor(props)
    {
        super(props);
        
        this.state={
            Redirect:false,
            redirectShowCartitems:false,
           
        };

    }


    componentDidMount = async () => {
      let getSession_Client_Email = await sessionStorage.getItem('user_email')
      if (getSession_Client_Email !== null) {
        await this.setState({
          client_email: getSession_Client_Email,
        })
      }
  
      let getSession_Client_ID = await sessionStorage.getItem('user_id')
      if (getSession_Client_ID !== null) {
        await this.setState({
          client_id: getSession_Client_ID,
        })
      }
      else
      { 
          this.setState({redirect: true})
      }
  
      let getSession_Client_Name = await sessionStorage.getItem('user_name')
      if (getSession_Client_Name !== null) {
        await this.setState({
          client_name: getSession_Client_Name,
        })
      }
      else
      { 
          this.setState({redirect: true})
      }
  
      console.log("getSession_Client_Email ====", getSession_Client_Email)
      console.log("getSession_Client_ID ====", getSession_Client_ID)
      console.log("getSession_Client_Name ====", getSession_Client_Name)
          
  }

    ShowCartitems()
    {
        this.setState({
            redirectShowCartitems:true
          // redirectQuestionTopicsList:true

        });
    }
    
    logout = () => {
      console.log("in logout")
      this.setState({redirect: true})
      sessionStorage.removeItem('user_id')
      sessionStorage.removeItem('user_name')
      sessionStorage.removeItem('user_email')
      
      // sessionStorage.removeItem('kol_id')
      // sessionStorage.removeItem('getSession_Project_Id')
  }

    

    render()    
    {

      
      if(this.state.redirectShowCartitems)
      {
        return (
          <Redirect to={{
            pathname:'/ShowCartitems'
           
          }}/>

        )
      }


     

        if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
        }



    return(
      //class="User-header"
    <div className="header-nav">
      <div className='container'>
      <header class="User-header" >
        <nav class="navbar navbar-light navbar-expand-lg fixed-top">
          <div class="container-fluid" >
            <a class="navbar-brand" href="#0">
              <img src={logo} class="User-logo_not" alt="Cetas"></img>
            </a>

              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              
            <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
              <div class="navbar-nav">
                
                <a class="nav-link" aria-current="page" href="#0"><span class="btn-label"><i class="fa fa-user"></i> </span>Hello,  { sessionStorage.getItem("user_name")} &nbsp;&nbsp;</a>
                
                {/* <a class="nav-link1" onClick={this.ShowCartitems.bind(this)} ><img src={cartimage} class="nav-cart" ></img></a> */}
              
                <a class="nav-link" onClick={this.ShowCartitems.bind(this)} ><img src={cartimage} class="nav-cart" ></img></a>
              
                <button onClick={this.logout.bind(this)} class="btn btn-danger btn-labeled"  ><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout</button>
              </div>
            </div>

          </div>
        </nav>
        
    </header>
    </div>
    </div>
        )

    }


}
export default UserHader;
