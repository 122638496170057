import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';


import Login from './Login';
//import Login from './Login/Login';
import QuestionTopicsList from './Employee/QuestionTopicsList';
import QuestionList from './Employee/QuestionList';
import ShowCartitems from './Employee/ShowCartitems';





const Routes = () => (

    
    // <BrowserRouter basename="/my-qb">
    <BrowserRouter basename="/">
   
    <Switch>
    <Route exact path='/' component={Login}/>    
    <Route path='/QuestionTopicsList' component={QuestionTopicsList} />
    <Route path='/QuestionList' component={QuestionList} />
    <Route path='/ShowCartitems' component={ShowCartitems}/>

    </Switch>
    </BrowserRouter>

    

   
);
export default Routes;