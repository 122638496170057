import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
 import {render, screen} from '@testing-library/react';
import ReactDOM  from 'react-dom/client';
import axios from  'axios';
import UserHeader from './UserHeader';
import { View, Image, StyleSheet } from 'react-native';

import {
  FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Alert,
  Container, Row, Col
} from 'reactstrap';
import {Img} from 'react-image';

import BootstrapTable from  'react-bootstrap-table-next';
import {jsPDF} from 'jspdf';
import html2pdf from 'html2pdf.js';
import * as html2canvas from 'html2canvas';

import Swal from 'sweetalert2';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';


//import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


//import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';

import paginationFactory from 'react-bootstrap-table2-paginator';
// import LoadingBar from "react-top-loading-bar";
// import { Chart } from "react-google-charts";
// import { Bar, Line } from 'react-chartjs-2';
// import ApexChart from './ApexChart';
// import Swal from 'sweetalert2';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { buildQueries } from '@testing-library/react';

const maxquestionselect=2;



class QuestionList extends Component
{
    constructor(props) {
    
      super(props);
        this.state = {

          ShowBar:false, 
          redirectQuestionList:false,
          redirectQuestionTopicsList:false,
          redirect: false,

          project_name:'',
          methodlogy:'',
          topics:'',
          sub_topics:'',
          category:'',
     

          user_id:'',
          topics_id:'',
          EditableStatus:'',
          QuestionlistForCheck:'',
      
          contentlist: [],
          salected_question_list:'',
          count_of_salected_question:0,
          Row_selected_question_id:'',

          topics_full_name:'',

          MethodlogyData:[],
          TopicData:[],
          SubTopicData:[],
          categoryData:[],
          projectnameData:[],

          // Add Selected Question List
          // isClient: false,
           //Client_Unique_id: '',
           //States for Client Add form
           SelectedQuestionList: {
            
                  user_id: '',
                  topics_id: '',
                  selected_question_id:'',
                  date: '0000-00-00',
              
           },

     // Column state for Bootstrap Table 
     columns: [
      {
        dataField: 'q_id',        
        text: 'Question ID',
        
          //sort: true,
        //hidden: true
      
      },
      {
        dataField: 'p_name',
        text: 'Name Project',
        //sort: true,
        //formatter: this.linkToDetails,
        
      },
      
      {
        dataField: 'methodlogy',
        text: 'Qual/Quant',
        short:true,
        filter: textFilter(),

        filterRenderer: (onFilter, column) => {
          this.onmethodlogyFilters = onFilter;
          return null;
      },
        
    //   filterRenderer: (onFilter, column) => {
    //     this.onStatusFilters = onFilter;
    //     return null;
    // },

      },
      {
        dataField: 'topic',
        text: 'Topic',
        
      },{
        dataField: 'sub_topic',
        text: 'Sub Topic',
        
      },{
        dataField: 'category',
        text: 'Category/Industry',
        
      },{
        dataField: 'question',
        text: 'Question',
        
      },{
        dataField: 'question_type',
        text: 'Question Type',
        
      },{
        dataField: 'instruction',
        text: 'Instruction',
        
      },{
        dataField: 'code',
        text: 'Code',
        
      },
      // {
      //   dataField: 'code',
      //   text: 'priority',
        
      // },
      // {
      //   dataField: 'p_name',
      //   text: 'Project Name',
      //   formatter: this.linkToDetails,
      //   // sort: true,
      //   filter: textFilter(),
      //   filterRenderer: (onFilter, column) => {
      //     this.onProjectNameFilter = onFilter;
      //     return null;
      //   },
      // },
      // {
      //   dataField: 'speciality_type',
      //   text: 'Speciality Type',
      // },
      // {
      //   dataField: "company_nm",
      //   text: "Company Name",
      //   // align: 'center',
      //   // headerAlign: 'center'
      // },
      // {
      //   dataField: "country",
      //   text: "Country",
      // },
      // {
      //   dataField: "noofkols",
      //   text: "No.of KOL",
      // },
    ],

  }    // End of states

    
  }

    //STRANGE
    onFilter = (event) => {
      console.log(event.target.value)
      const { value } = event.target.value;
      //this.onmethodlogyFilters(value);
     //var $top_id=this.state.topics_id;
     //alert(event.target[0].value);  
     if(event.target[0].value == "Select Project Name")
      {
       // this.getquestionlist($top_id,event.target.value,"","","","");
       this.getquestionlist(this.state.topics_id,event.target.value,this.state.methodlogy,this.state.topics,this.state.sub_topics,this.state.category);
    
        this.setState({
          project_name:event.target.value,
        });
      
       //alert(this.state.project_name);
      }
     
     else if(event.target[0].value =="Select Method")
      {
        this.getquestionlist(this.state.topics_id,this.state.project_name,event.target.value,this.state.topics,this.state.sub_topics,this.state.category);
    
        this.setState({
          methodlogy:event.target.value,
         });
          //this.getquestionlist($top_id,"",event.target.value,"","","");
      }
      
      else if(event.target[0].value =="Select Topic")
      {
       // $topics=event.target.value;
       this.getquestionlist(this.state.topics_id,this.state.project_name,this.state.methodlogy,event.target.value,this.state.sub_topics,this.state.category);
    
       this.setState({
        topics:event.target.value,
       });
       
       //this.getquestionlist($top_id,"","",event.target.value,"","");
      }
      else if(event.target[0].value == "Select Sub Topic")
      {
        this.getquestionlist(this.state.topics_id,this.state.project_name,this.state.methodlogy,this.state.topics,event.target.value,this.state.category);
    
        this.setState({
          sub_topics:event.target.value,
         });
        //$sub_topics=event.target.value;
          //this.getquestionlist($top_id,"","","",event.target.value,"");
      }
      else if(event.target[0].value =="Select Category Industry")
      {

        this.getquestionlist(this.state.topics_id,this.state.project_name,this.state.methodlogy,this.state.topics,this.state.sub_topics,event.target.value);
    
        this.setState({
          category:event.target.value,
         });
          //this.getquestionlist($top_id,"","","","",event.target.value);
      }
      
     // $topics_id,$project_name,$methodlogy,$topics,$sub_topics,$category
      // this.getquestionlist(this.state.topics_id,this.state.project_name,this.state.methodlogy,this.state.topics,this.state.sub_topics,this.state.category);
    
  };

//

//   //STRANGE
//   onFilter = (event) => {
//     console.log(event.target.value)
//     const { value } = event.target;
//     this.onStatusFilters(value);
// };

    logout = () => {
      console.log("in logout")
      this.setState({ redirect: true })
      sessionStorage.removeItem('topics_id')
      
    }

    

  //  sessiondata = async () =>
  //   {
  //         let getsession_user_id= await sessionStorage.getItem('user_id');
  //          if(getsession_user_id !== null)
  //         {
  //             await this.setState({

  //               user_id:getsession_user_id
              
  //             })
  //             this.SelectedQuestionList.user_id=getsession_user_id;
  //             alert(getsession_user_id);
  //         }
      
  //         let getsession_topics_id = await sessionStorage.getItem('topic_id');
  //         alert(getsession_topics_id);
  //         if(getsession_topics_id !== null)
  //         {
  //             await this.setState({

  //               topics_id:getsession_topics_id
  //             })

  //             this.SelectedQuestionList.topics_id=getsession_topics_id;
  //         }    

  //   }

  

     
//let  salected_question_list='';
  add_selected_topics =(e) =>
  {   

    this.state.count_of_salected_question = this.state.count_of_salected_question +1;
    //alert(this.state.count_of_salected_question);
    //this.state.SelectedQuestionList.selected_question_id += e + ','
    
    // if(this.state.SelectedQuestionList.selected_question_id == '')
    // {
    //   this.state.SelectedQuestionList.selected_question_id += e 
    // }
    // else
    // {      
    //   this.state.SelectedQuestionList.selected_question_id += ',' + e 
    // }

   // alert(this.state.Row_selected_question_id);

    if(this.state.Row_selected_question_id !== '')
    {
      
          if((this.state.Row_selected_question_id).includes(e))
          {
              let Newtxt='';
              let oldtext=(this.state.Row_selected_question_id);
            
              if(oldtext.includes("'"+e+"'"+','))
              {
                Newtxt=(oldtext).replace("'"+e+"'"+',',"");              
                this.setState({
                  Row_selected_question_id:Newtxt,
                })
              }
              else if(oldtext.includes(e))
              {
                Newtxt=(oldtext).replace(','+"'"+e+"'","");
             
                this.setState({
                  Row_selected_question_id:Newtxt,
                })
              }
             
          }
          else
          {
            this.state.Row_selected_question_id += ',' + "'"+e+"'"

          }
      }
      else
      {
        this.state.Row_selected_question_id +=  "'"+e+"'"
      }

      //alert(this.state.Row_selected_question_id);
      this.state.SelectedQuestionList.selected_question_id =this.state.Row_selected_question_id;
     
   }


  //topics_id

    
    
componentDidMount = async () => {

  //let Editable_topics_id= await sessionStorage.getItem('Editabletopics_id');
 // alert(Editable_topics_id);

  let getsession_topics_id = await sessionStorage.getItem('topics_id');
  
  if(getsession_topics_id !== null)
  {
      await this.setState({

        topics_id:getsession_topics_id
      })

      this.state.SelectedQuestionList.topics_id = getsession_topics_id;

  }


  let getsession_topics = await sessionStorage.getItem('topics');
 // alert(getsession_topics);
  //topics_full_name
  if(getsession_topics !== null)
          {
              await this.setState({

                topics_full_name:getsession_topics,                
              
              });
  
          }

  
  //alert(this.state.topics_full_name);

          let getsession_user_id= await sessionStorage.getItem('user_id');
           if(getsession_user_id !== null)
          {
              await this.setState({

                user_id:getsession_user_id,                
              
              })

              this.state.SelectedQuestionList.user_id = getsession_user_id;
              
          }



    this.getquestionlist(this.state.topics_id,"","","","","");
    this.getmethodlogyList();
    this.getTopicList();
    this.getSubTopicList();
    this.getProjectList();
    this.getcategoryList();
    
   
  
}

// To get SubTopic 
getSubTopicList = () => { 
  
  console.log("in SubTopic list");
  console.log("SubTopic",'SubTopic');
  
 // const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
 //const url = global.websiteUrl+'/booklet/service/User/get_dropdown_list';
 const url = global.websiteUrl+'/service/User/get_dropdown_list';
 
  let formData = new FormData();
  formData.append('conditiontext', 'sub_topic');
  formData.append('topics_id', this.state.topics_id);
  const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
  }
 
  axios.post(url, formData, config)
    .then(response => {
      console.log(response);
      var SubTopicData = response.data;
     // alert("hi");

      console.log("SubTopicData", JSON.stringify(SubTopicData));
      if (response.data.status == true) {
        this.setState({
          SubTopicData: response.data.Selected_list,
        })
       // alert(this.state.SubTopicData[0]['fetchdata'])
      }
      else {
        
        //alert("No Procedure Data");
        this.setState({
          SubTopicData: [],
        })
      }
      console.log("SubTopicData", this.state.SubTopicData)
    })
    .catch(error => {
      console.log(error);
    });
}



// To get Topic 
getTopicList = () => {
  console.log("in Topic list");
  console.log("Topic",'Topic');
  
 // const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
 //const url = global.websiteUrl+'/booklet/service/User/get_dropdown_list';
 const url = global.websiteUrl+'/service/User/get_dropdown_list';
 
  let formData = new FormData();
  formData.append('conditiontext', 'topic');
  formData.append('topics_id', this.state.topics_id);
  const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
  }
 
  axios.post(url, formData, config)
    .then(response => {
      console.log(response);
      var TopicData = response.data;
     // alert("hi");

      console.log("TopicData", JSON.stringify(TopicData));
      if (response.data.status == true) {
        this.setState({
          TopicData: response.data.Selected_list,
        })
        //alert(this.state.TopicData[0]['fetchdata'])
      }
      else {
        
        //alert("No Procedure Data");
        this.setState({
          TopicData: [],
        })
      }
      console.log("TopicData", this.state.TopicData)
    })
    .catch(error => {
      console.log(error);
    });
}






 // To get methodlogy 
 getmethodlogyList = () => {
  console.log("in methodlogy list");
  console.log("methodlogy",'methodlogy');
  
 // const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
 //const url = global.websiteUrl+'/booklet/service/User/get_dropdown_list';
 const url = global.websiteUrl+'/service/User/get_dropdown_list';
  
  let formData = new FormData();
  formData.append('conditiontext', 'methodlogy');
  formData.append('topics_id', this.state.topics_id);
  const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
  }
 
  axios.post(url, formData, config)
    .then(response => {
      console.log(response);
      var MethodlogyData = response.data;
     // alert("hi");

      console.log("MethodlogyData", JSON.stringify(MethodlogyData));
      if (response.data.status == true) {
        this.setState({
          MethodlogyData: response.data.Selected_list,
        })
        //alert(this.state.MethodlogyData[0]['fetchdata'])
      }
      else {
        
        //alert("No Procedure Data");
        this.setState({
          MethodlogyData: [],
        })
      }
      console.log("MethodlogyData", this.state.MethodlogyData)
    })
    .catch(error => {
      console.log(error);
    });
}

 // To get category 
 getcategoryList = () => {
  console.log("in category list");
  console.log("category",'category');
  
 // const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
 //const url = global.websiteUrl+'/booklet/service/User/get_dropdown_list';
 const url = global.websiteUrl+'/service/User/get_dropdown_list';
  
  let formData = new FormData();
  formData.append('conditiontext', 'category');
  formData.append('topics_id', this.state.topics_id);
  const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
  }
 
  axios.post(url, formData, config)
    .then(response => {
      console.log(response);
      var categoryData = response.data;
     // alert("hi");

      console.log("categoryData", JSON.stringify(categoryData));
      if (response.data.status == true) {
        this.setState({
          categoryData: response.data.Selected_list,
        })
        //alert(this.state.MethodlogyData[0]['fetchdata'])
      }
      else {
        
        //alert("No Procedure Data");
        this.setState({
          categoryData: [],
        })
      }
      console.log("categoryData", this.state.categoryData)
    })
    .catch(error => {
      console.log(error);
    });
}

 // To get category 
 getProjectList = () => {
  console.log("in project list");
  console.log("p_name",'p_name');
  
 // const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
 //const url = global.websiteUrl+'/booklet/service/User/get_dropdown_list';
 const url = global.websiteUrl+'/service/User/get_dropdown_list';
  
  let formData = new FormData();
  formData.append('conditiontext', 'p_name');
  formData.append('topics_id', this.state.topics_id);
  const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
  }
 
  axios.post(url, formData, config)
    .then(response => {
      console.log(response);
      var projectnameData = response.data;
     // alert("hi");

      console.log("projectnameData", JSON.stringify(projectnameData));
      if (response.data.status == true) {
        this.setState({
          projectnameData: response.data.Selected_list,
        })
        //alert(this.state.MethodlogyData[0]['fetchdata'])
      }
      else {
        
        //alert("No Procedure Data");
        this.setState({
          projectnameData: [],
        })
      }
      console.log("projectnameData", this.state.projectnameData)
    })
    .catch(error => {
      console.log(error);
    });
}

getquestionlist($topics_id,$project_name,$methodlogy,$topics,$sub_topics,$category)
  {

    this.setState({
      ShowBar: true,
      progressBar: true,
    });

    const options = {
      method: 'POST',
    }  
  
    let formData = new FormData();
    formData.append('topics_id', $topics_id);
    formData.append('project_name', $project_name);   
    formData.append('methodlogy', $methodlogy);
    formData.append('topic', $topics);
    formData.append('sub_topic', $sub_topics);
    formData.append('category', $category);
    
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
  
    
    const url =global.websiteUrl+'/service/User/get_question_list';
   // const url =global.websiteUrl+'/booklet/service/User/get_question_list';
    //const url='http://localhost/booklet/service/User/get_question_list';
    axios.post(url,formData, config)
    .then(response => {
      console.log(response);
  
      var contentlist = response.data.Topics_Details;   
       
      console.log("contentlist", JSON.stringify(response.data.Topics_Details));
      if(response.data.status == true)    
      {
     
          this.setState({
              contentlist:[],
              contentlist: response.data.Topics_Details,
          })
  
        
       }
       else
       {
        this.setState({
          contentlist:[],
      })
  
      }
  
      console.log("contentlist====", this.state.contentlist)

      
      this.GetAndClickCheckBox();

      this.setState({
        ShowBar: false,
        progressBar: false,
      });

    }
    )


    .catch(error => {
      console.log(error);
      alert("Error Occurs");
      this.setState({
        ShowBar: false,
        progressBar: false,
      });
    });
  };



GetAndClickCheckBox()
{
/// ***************** Cecked Box **********************

let options = {
  method:'POST',  
}

let formData = new FormData();
formData.append('user_id',this.state.user_id);
formData.append('status','New');
formData.append('topics_id', this.state.topics_id);

const config={
  // headers: { 'content-type': 'multipart/form-data' }
};
//const url='http://localhost/booklet/service/User/get_selected_question_list';
//const url=global.websiteUrl+'/booklet/service/User/get_selected_question_list';
const url=global.websiteUrl+'/service/User/get_selected_question_list';

axios.post(url,formData,config)
.then( response =>{
  console.log(response);
  var KOL_Personal_Info = response.data.Selected_list;
  console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));

  if(response.data.status ==true)
  {
      this.setState({
         // SelectedQuestionlist:response.data.Selected_list,
         QuestionlistForCheck:KOL_Personal_Info,

      })
  }  
 

console.log("QuestionlistForCheck", this.state.QuestionlistForCheck);

//alert(this.state.QuestionlistForCheck.length);

//alert(this.state.contentlist[0]["question_id"]);

for($i=0;$i<this.state.contentlist.length;$i++)
{
  //alert(this.state.contentlist[$i]["question_id"]);
 var $qid=this.state.contentlist[$i]["question_id"];
 //alert(document.getElementById($qid));
 if( document.getElementById($qid) != null)
 {
  document.getElementById($qid).checked = false;
 }
}

if(this.state.QuestionlistForCheck.length >0)
{

    // ******** checked Check Box
    var $Vvalue=this.state.QuestionlistForCheck[0].selected_question_id;
    
    if( $Vvalue.includes(','))
    {
      var $Ve= $Vvalue.split(',');
      for(var $i=0; $i < $Ve.length ; $i++)
      {
          if( document.getElementById($Ve[$i].replace("'","").replace("'","")) != null)
          {
          document.getElementById($Ve[$i].replace("'","").replace("'","")).checked = false;
          document.getElementById($Ve[$i].replace("'","").replace("'","")).checked = true;
          }
      }

    }
    else
    {
      var $Vvalue=this.state.QuestionlistForCheck[0].selected_question_id.replace("'","").replace("'","");
     // alert(document.getElementById($Vvalue));
      if( document.getElementById($Vvalue) != null)
      {
        document.getElementById($Vvalue).checked = false;
        document.getElementById($Vvalue).checked = true;
      }
      

    }
}


})


.catch(error => {
  console.log(error);
  alert("Error Occurs");
  this.setState({
    ShowBar: false,
    progressBar: false,
  });
});

}


GetexistingSelectedData()
{
/// ***************** Cecked Box **********************

let options = {
  method:'POST',  
}

let formData = new FormData();
formData.append('user_id',this.state.user_id);
formData.append('status','New');
formData.append('topics_id', this.state.topics_id);

const config={
  // headers: { 'content-type': 'multipart/form-data' }
};
//const url='http://localhost/booklet/service/User/get_selected_question_list';
//const url=global.websiteUrl+'/booklet/service/User/get_selected_question_list';
const url=global.websiteUrl+'/service/User/get_selected_question_list';

axios.post(url,formData,config)
.then( response =>{
  console.log(response);
  var KOL_Personal_Info = response.data.Selected_list;
  console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));

  if(response.data.status ==true)
  {
      this.setState({
         // SelectedQuestionlist:response.data.Selected_list,
         QuestionlistForCheck:KOL_Personal_Info,

      })
  }  
 

console.log("QuestionlistForCheck", this.state.QuestionlistForCheck);
//alert("ff: "+this.state.QuestionlistForCheck);

})


.catch(error => {
  console.log(error);
  alert("Error Occurs");
  this.setState({
    ShowBar: false,
    progressBar: false,
  });
});

}


saveselectedquestionlist=()=>
{
  
  if(this.state.QuestionlistForCheck.length>0)
  {
    this.Updatequestionlist();    
  
  }
  else
  {
    this.savequestionlist();
  
  }

}


Reset_Search=()=>
{
  window.location.reload(true);
}


Updatequestionlist=()=>
{ 

  this.setState({
    ShowBar: true,
    progressBar: true,
  });

  if(this.state.QuestionlistForCheck.length>0)
  {
      var $exitingSelectedQuestionList= this.state.QuestionlistForCheck[0].selected_question_id;
      //alert("OldVal: " + $exitingSelectedQuestionList)
  }
  else
  {
    var $exitingSelectedQuestionList="";
  }


  // if(this.state.SelectedQuestionList.selected_question_id.length >0)
  // {

  var $newSelectedQuestionList=this.state.SelectedQuestionList.selected_question_id;
  //alert("NewVal: " + $newSelectedQuestionList)

  var $modifiedfinalselectedQuestionlist=$newSelectedQuestionList+","+$exitingSelectedQuestionList
  //alert("modiVal: " + $modifiedfinalselectedQuestionlist)


 // const url = global.websiteUrl+'/service/Client/create_client';
 // const url=global.websiteUrl+'/booklet/service/User/Update_selected_question';
 const url=global.websiteUrl+'/service/User/Update_selected_question';
 
  let formData = new FormData();
  
  formData.append('user_id', this.state.SelectedQuestionList.user_id);
  formData.append('topics_id', this.state.SelectedQuestionList.topics_id);
  //formData.append('selected_question_id', this.state.SelectedQuestionList.selected_question_id);
  formData.append('selected_question_id', $modifiedfinalselectedQuestionlist);  
  formData.append('status', 'New');
 
  const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
  }
  axios.post(url, formData, config)
      .then(response => {
          console.log(response);

          //alert(response.data.status);

          var SelectedQuestionList = response.data;
          console.log("clientStatus", JSON.stringify(SelectedQuestionList));
          
          var clientstatus = response.data.status;
          var message = response.data.message;
          console.log("message", message)
          
          if (clientstatus == true) {
              Swal.fire({
                  icon: 'success',
                  //text: message, 
                  text:'update success',                 
                  confirmButtonColor: '#A90007',
                  confirmButtonText: 'OK',                                
                  
              })
              this.state.SelectedQuestionList.selected_question_id='';
              this.setState({redirectQuestionTopicsList:true});
          }
          else {
              Swal.fire({
                  icon: 'error',
                  //text: message,
                  text:'unsuccess',
                  confirmButtonColor: '#A90007',
                  confirmButtonText: 'OK'
              })
          }
          this.setState({
            ShowBar: false,
            progressBar: false,
          });
      })

    


      .catch(error => {
          console.log(error);
      });
  
    // }
    // else
    // {

    //   alert("Select minimum 1 question")
    // }
    

} 



savequestionlist=()=>
{

this.setState({
    ShowBar: true,
    progressBar: true,
  });
  // if(this.state.SelectedQuestionList.selected_question_id.length >0)
  // {


 // const url = global.websiteUrl+'/service/Client/create_client';
 // const url=global.websiteUrl+'/booklet/service/User/save_selected_question';
 const url=global.websiteUrl+'/service/User/save_selected_question';
  
 let formData = new FormData();
  
  formData.append('user_id', this.state.SelectedQuestionList.user_id);
  formData.append('topics_id', this.state.SelectedQuestionList.topics_id);
  formData.append('selected_question_id', this.state.SelectedQuestionList.selected_question_id);
  
  formData.append('status', 'New');
  formData.append('date', this.state.SelectedQuestionList.date);
 
  const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
  }
  axios.post(url, formData, config)
      .then(response => {
          console.log(response);
          var SelectedQuestionList = response.data;
          console.log("clientStatus", JSON.stringify(SelectedQuestionList));
  
          var clientstatus = response.data.status;
          var message = response.data.message;
          console.log("message", message)
          if (clientstatus == true) {
              Swal.fire({
                  icon: 'success',
                  //text: message, 
                  text:'Selected list saved',                 
                  confirmButtonColor: '#A90007',
                  confirmButtonText: 'OK',                                
                  
              })
              this.state.SelectedQuestionList.selected_question_id='';
              this.setState({redirectQuestionTopicsList:true});
          }
          else {
              Swal.fire({
                  icon: 'error',
                  //text: message,
                  text:'unsuccess',
                  confirmButtonColor: '#A90007',
                  confirmButtonText: 'OK'
              })
          }
          // this.setState({
          //   SelectedQuestionList: {
          //         user_id: '',
          //         topics_id: '',
          //         selected_question_id: '',
          //         date: '',
          //     }
          // });
          this.setState({
            ShowBar: false,
            progressBar: false,
          });
      })

    


      .catch(error => {
          console.log(error);
      });
  
    // }
    // else
    // {

    //   alert("Select minimum 1 question")
    // }
    

} 

  
  BackToQuestionTopicsList() {
    this.setState({ redirectQuestionTopicsList: true });
  }



    render()
    {
  
  
      if (this.state.redirectQuestionTopicsList) {
        return (<Redirect to={{
          pathname: '/QuestionTopicsList',
        }} />)
      }
      
      
  
      if (this.state.redirect) {
        return (<Redirect to={'/'} />)
      }


        return(
            <div>
<div>
          {this.state.ShowBar ? <Modal isOpen={true}  centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' style={{ textAlign:'center'}} />
          </Modal> : null}
        </div>
            <UserHeader />
       
<div  className='pg-content'> 
        <div class="container-fluid" id="mainDashboard">
          <div class="row mt-3">
            <div class="col-lg-9 col-md-9 col-sm-12 col-12" style={{width:'100%'}}>
              
               <div className="back-section">
                  <a onClick={this.BackToQuestionTopicsList.bind(this)}>
                    <img src={require('../Assets/Images/back-arrow.svg')} />
                    Back
                  </a>
                </div>


          <div class="col-lg-12 col-md-12 col-sm-12 col-12" style={{marginBottom:'20px'}} >
            <div class="card" style={{marginBottom:'20px' , backgroundColor:'#17BC8B'}}>                  
              <div class="card-body">

              <div class="container">
                  <div class="row">

                  <div class="col-sm">
                    <select className="form-control" style={{backgroundColor:'#fff'}}  id="select_project_name" onChange={(e) => { this.onFilter(e) }}>
                           
                        <option value="Select Project Name" > Select Project Name</option>
                           
                              {this.state.projectnameData.map((option) => (
                        <option value={option.fetchdata}>{option.fetchdata}</option>

                      ))}
                           
                        </select>
              </div>


                <div class="col-sm">
                    <select className="form-control" style={{backgroundColor:'#fff'}} id="exampleFormControlSelect1" data={this.state.MethodlogyData} onChange={(e) => { this.onFilter(e) }}>
                           
                    <option value="Select Method">Select Method</option>
                           
                        {this.state.MethodlogyData.map((option) => (
                        <option value={option.fetchdata}>{option.fetchdata}</option>

                      ))}
                           
                        </select>
              </div>



    <div class="col-sm">
                    <select className="form-control" style={{backgroundColor:'#fff'}} id="exampleFormControlSelect1" onChange={(e) => { this.onFilter(e) }}>
                        <option value="Select Topic"> Select Topic</option>
                        {this.state.TopicData.map((option) => (
                        <option value={option.fetchdata}>{option.fetchdata}</option>
                      ))}
                        {/* <option>Awareness</option>
                        <option>Usage</option>
                        <option>Awareness</option> */}
                    </select>
    </div>
    <div class="col-sm">
    <select className="form-control" style={{backgroundColor:'#fff'}} id="exampleFormControlSelect1" onChange={(e) => { this.onFilter(e) }}>
                        <option value="Select Sub Topic">Select Sub Topic</option>
                        {this.state.SubTopicData.map((option) => (
                        <option value={option.fetchdata}>{option.fetchdata}</option>
                      ))}
                        {/* <option>Comes to mind</option>
                        <option>Installation</option>
                        <option>Comes to mind</option> */}
                    </select>
    </div>

    <div class="col-sm">
                    <select className="form-control" style={{backgroundColor:'#fff'}} id="exampleFormControlSelect1" onChange={(e) => { this.onFilter(e) }}>
                        <option value="Select Category Industry">Select Category/Industry</option>
                        {this.state.categoryData.map((option) => (
                        <option value={option.fetchdata}>{option.fetchdata}</option>
                      ))}
                        {/* <option>Comes to mind</option>
                        <option>Installation</option>
                        <option>Comes to mind</option> */}
                    </select>
    </div>

    <div class="col-sm">
    <Button onClick={this.Reset_Search}  className='reset_button' >Reset Search</Button>
      
    </div>


  </div>
</div>


              
              </div>
              </div>
              </div>
              

               
                <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
                  <div class="card">                  
                    <div class="card-body">                    
                    <h4><b style={{color:'#2DADF5'}}> <u> {this.state.topics_full_name}-({this.state.contentlist.length})</u> </b></h4>
                    <tr><br></br></tr>
                                  
                      <div class="table-responsive mt-2">
                        {  
                         this.state.contentlist.map((el,i) => (
                          <div>   
                                                                                                    
                            {               
                              
                             (i===0)?
                              (
                                <table >                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        <td className='check_' style={{backgroundColor:'#2DADF5', color:'#fff' , paddingTop:'10px',paddingBottom:'10px'}}>Check </td>                                        
                                        <td className='grid_colomn_style1'style={{backgroundColor:'#2DADF5', color:'#fff', paddingTop:'10px',paddingBottom:'10px'}}>Name Project</td>     
                                        <td className='grid_colomn_style1' style={{backgroundColor:'#2DADF5', color:'#fff', paddingTop:'10px',paddingBottom:'10px'}}>Qual/Quant </td>
                                         <td className='grid_colomn_style1' style={{backgroundColor:'#2DADF5', color:'#fff', paddingTop:'10px',paddingBottom:'10px'}}>Topic</td>
                                        <td className='grid_colomn_style1' style={{backgroundColor:'#2DADF5', color:'#fff', paddingTop:'10px',paddingBottom:'10px'}}>Sub Topic</td> 
                                        <td  className='grid_colomn_style1' style={{backgroundColor:'#2DADF5', color:'#fff', paddingTop:'10px',paddingBottom:'10px'}}>Category/Industry </td>
                                        <td className='Question_' style={{backgroundColor:'#2DADF5', color:'#fff', paddingTop:'10px',paddingBottom:'10px'}}>Question</td>
                                        {/* <td className='grid_colomn_style1'>Question Type</td> */}
                                        {/* <td className='grid_colomn_style1'>Instruction</td>
                                        <td className='grid_colomn_style1'>Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>      
                                            
                            <tr style={{ backgroundColor:this.state.contentlist[i].priority=="Yes"?'#F9D28A':'white'}} >
                              
                              <td className='check_' >   
                                          <input type='checkbox'
                                              id={this.state.contentlist[i].question_id}
                                              name={this.state.contentlist[i].question_id}
                                              onChange={(e) => {
                                                
                                                { this.add_selected_topics(this.state.contentlist[i].question_id) }
                                                
                                              }}
                                          /> 
                                       {
                            }     
                                    
                                
                                </td>
                              
                             {/* <td className='grid_colomn_style1'>{this.state.contentlist[i].question_id}</td> */}
                              
                              <td className='grid_colomn_style1'>{this.state.contentlist[i].p_name}</td>
                              <td className='grid_colomn_style1'>{this.state.contentlist[i].methodlogy}</td>
                              <td className='grid_colomn_style1'>{this.state.contentlist[i].topic}</td>
                              <td className='grid_colomn_style1'>{this.state.contentlist[i].sub_topic}</td>
                              <td className='grid_colomn_style1'>{this.state.contentlist[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.contentlist[i].question}
                                
                              </td>

                               {/* <td className='grid_colomn_style1'>{this.state.contentlist[i].question_type}</td> */}
                              {/* <td className='grid_colomn_style1'>{this.state.contentlist[i].instruction}</td>
                              <td className='grid_colomn_style1'>{this.state.contentlist[i].code}</td>  */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }


                                                                  {/* <BootstrapTable
                                                                    // {...props.baseProps}
                                                                    noDataIndication="Table is Empty"
                                                                    keyField="q_id"
                                                                    data={this.state.contentlist}
                                                                    columns={this.state.columns}
                                                                    striped
                                                                    hover
                                                                    condensed
                                                                    loading={true}
                                                                    //pagination={paginationFactory(options)}
                                                                    filter={filterFactory()}
                                                                    rowStyle={ { backgroundColor: 'White' } }

                                                                />
 */}

                      
                      {/* <ToolkitProvider
                        keyField="q_id"
                        data={this.state.contentlist}
                        columns={this.state.columns}
                       
                      >
                        {
                          props => (
                            <div>
                              <BootstrapTable
                               // keyField='q_id'
                               // selectRow={selectRow}
                                //data={this.state.contentlist} columns={this.state.columns}
                                 //data={this.state.contentlist}
                                 //columns={this.state.columns}
                                 //filter={filterFactory()}
                                                                  
                                // striped
                                // hover
                                // condensed
                                // tabIndexCell
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider> */}

                      </div>
                    </div>
                    {
    (this.state.contentlist.length>0)?
    (       
                    <Button onClick={this.saveselectedquestionlist} className="button_style" style={{marginBottom:'50px'}}>Save In Cart</Button>
                   

                    ):(<div className='showCartItem'>
  <p>no data list</p>

</div>)

 }       

                  </div>
  
                </div>

               
                
              </div>
            </div>
       
            </div>
</div>
            
    <footer>
        <div className="footer-top text-center" >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center" style={{height:'20px'}}>
                        {/* <div className="col-auto social-icons">
                            <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                            <a href="https://twitter.com/cetashealthcare" target="_blank"><i className='bx bxl-twitter'></i></a>
                            <a href="https://www.youtube.com/channel/UCzJPjYnPXRZjWs-H1IqvMFQ" target="_blank"><i className='bx bxl-youtube'></i></a>
                        </div>
                        <div className="col-auto conditions-section">
                            <a href="https://www.cetas-healthcare.com/privacy_policy/" target="_blank" rel="opener">Privacy</a>
                            <a href="https://www.cetas-healthcare.com/terms-of-use/" target="_blank" rel="opener">Terms</a>
                            <a href="https://www.cetas-healthcare.com/cookie-notice/" target="_blank" rel="opener">Cookie Notice</a>
                        </div> */}
                        <p className="mb-0">Copyright Cetas 2022. All rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
          </div>



        );

  
        }



  }
export default QuestionList;