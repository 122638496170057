import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import ReactDOM  from 'react-dom/client';
import axios from  'axios';
import UserHeader from './UserHeader';
import { View, Image, StyleSheet } from 'react-native';
import {Img} from 'react-image';

import BootstrapTable from  'react-bootstrap-table-next';
import {jsPDF} from 'jspdf';
import html2pdf, { f } from 'html2pdf.js';
import * as html2canvas from 'html2canvas';

import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';


import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';




class QuestionTopicsList extends Component
{
    constructor(props) {
    
      super(props);
        this.state = {
          ShowBar:false,
          redirectQuestionList:false,
          contentlist: [],
          EditableStatus:'',

     
     // Column state for Bootstrap Table 
     columns: [
      {
        dataField: 'topics_id',
        text: 'Question ID',
          //sort: true,
        hidden: true
      
      },
      {
        dataField: 'topics',
        text: 'QUESTION TOPICS (per tab in DG and QRE)',
        sort: true,
        formatter: this.linkToDetails,
        
      },
      
      {
        dataField: 'no_of_question',
        text: 'Number of questions',
        
      },



      // {
      //   dataField: 'p_name',
      //   text: 'Project Name',
      //   formatter: this.linkToDetails,
      //   // sort: true,
      //   filter: textFilter(),
      //   filterRenderer: (onFilter, column) => {
      //     this.onProjectNameFilter = onFilter;
      //     return null;
      //   },
      // },
      // {
      //   dataField: 'speciality_type',
      //   text: 'Speciality Type',
      // },
      // {
      //   dataField: "company_nm",
      //   text: "Company Name",
      //   // align: 'center',
      //   // headerAlign: 'center'
      // },
      // {
      //   dataField: "country",
      //   text: "Country",
      // },
      // {
      //   dataField: "noofkols",
      //   text: "No.of KOL",
      // },
    ],

  }    // End of states

    
    }



    logout = () => {
      console.log("in logout")
      this.setState({ redirect: true })
      sessionStorage.removeItem('topics_id')
      
    }

    questionlistInfoNav(topics_id,topics) {
     // alert(topics);
      sessionStorage.setItem('topics_id', '');  
      sessionStorage.setItem('topics_id', topics_id);
      
      sessionStorage.setItem('topics', '');  
      sessionStorage.setItem('topics', topics);
      
      console.log('Navigation of QuestionList', topics_id,topics);
      this.setState({
        redirectQuestionList: true,
       // redirectClientViewKolList: true,
      })
    }

  //Funtion to apply links to project names
  linkToDetails = (cell, row) => {
    var topics_id = row.topics_id;
    return (
      <div>
        <a style={{
          fontFamily: 'arial, sans-serif', color: '#069',
          textDecoration: 'underline', cursor: 'pointer' ,textAlign:'left'
        }}
          onClick={this.questionlistInfoNav.bind(this, topics_id,row.topics)}> {row.topics} </a>
      </div>
    )
  }
    
    


    
    
componentDidMount = async () => {
  
  
  this.setState({
    ShowBar: true,
    progressBar: true,
  })

  const options = {
    method: 'POST',
  }

  const url =global.websiteUrl+'/service/User/get_question_topics_list';
  
  //const url =global.websiteUrl+'/booklet/service/User/get_question_topics_list';
  //const url='http://localhost/booklet/service/User/get_content_list';
  fetch(url, options)
  .then((Response) => Response.json())    
  .then(response => {
    console.log(response);
    var lgdata = response.data;
      
    console.log("contentlist", JSON.stringify(lgdata));
          
    this.setState({
      contentlist: response.data,
      
    })
    console.log("contentlist", this.state.contentlist);  

    this.setState({
      ShowBar: false,
      progressBar: false,
    });

  }
    

  )

    
    .catch(error => {
      console.log(error);
      alert("Error Occurs");
      this.setState({
        ShowBar: false,
        progressBar: false,
      });
    });

    
}

  

  // rediect funcs
  BackToDashboard() {
      this.setState({ RedirectFlag: true })
  }

  
    render()
    {

      if (this.state.redirectQuestionList) {
        return (<Redirect to={{
          pathname: '/QuestionList',
        }} />)
      }
      
      if (this.state.redirect) {
        return (<Redirect to={'/'} />)
      }

        return(
            <div>
        <div>
          {this.state.ShowBar ? <Modal isOpen={true}  centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' style={{ textAlign:'center'}} />
          </Modal> : null}
        </div>
        <UserHeader />
        {/* <div class="text-center" >
          {
            
               this.state.contentlist.map((el,i) => (
                <div>
                  <h1> {this.state.contentlist[i].q_topics} </h1>
                </div>
               )

               )

          }
          </div> */}
<div  className='pg-content'>
          <div class="container-fluid" id="mainDashboard" >
          <div class="row mt-3">
            <div class="col-lg-7 col-md-9 col-sm-9 col-9">
              
               <div >
                   
                <div class="col-lg-12 col-md-9 col-sm-9 col-9">
                  <div class="card">
               
                    <div class="card-body">
                    <h4><b>Question Bank</b></h4>
                    <tr><br></br></tr>
               
                      <div class="table-responsive mt-2">
                        <table class='table table-hover' >
                        <ToolkitProvider
                        keyField="topics_id"
                        data={this.state.contentlist}
                        columns={this.state.columns}
                       // search={this.customMatchFunc}
                      >
                        {
                          props => (
                            <div>
                              <BootstrapTable
                                keyField='topics_id'
                                data={this.state.contentlist} columns={this.state.columns}
                                //filter={filterFactory()}
                                // selectRow={ selectRow } 
                                //pagination={paginationFactory(options)}
                                striped
                                hover
                                condensed
                                tabIndexCell
                              />
                              <hr></hr>
                              {/* <ExportCSVButton  class="cetas-button" { ...props.csvProps }>Export</ExportCSVButton> */}
                            </div>
                          )
                        }
                      </ToolkitProvider>





               
                        {/* <thead>
                            <tr>
                              <th scope="col" style={{ background:'#242c43'}}>QUESTION TOPICS (per tab in DG and QRE) </th>
                              <th scope="col" style={{background:'#242c43'}}>Number of questions</th>
                             
                            </tr>
                          </thead>

                         <tbody>
                          <tr>
                              <td><a href="Dashboard1.html">Brand Awareness and Usage</a></td>
                              <td>42</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Brand Perception/Satisfaction and Switch</a></td>
                              <td>48</td>
                             
                            </tr>
                            <tr>
                              <td><a href="Dashboard1.html">Campaign Awareness</a></td>
                              <td>13</td>
                             
                            </tr>
                            <tr>
                              <td><a href="Dashboard1.html">Claim Test</a></td>
                              <td>3</td>
                             
                            </tr>
                            <tr>
                              <td><a href="Dashboard1.html">Concept Testing/Message Testing/Value Proposition Testing</a></td>
                              <td>83</td>
                             
                            </tr>
                            <tr>
                              <td><a href="Dashboard1.html">Conjoint (including MaxDiff)</a></td>
                              <td>3</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Current Situation Testing</a></td>
                              <td>126</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Future Situation Testing</a></td>
                              <td>97</td>
                             
                            </tr>

                            

                            <tr>
                              <td><a href="Dashboard1.html">Market Sizing</a></td>
                              <td>30</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Naming Research</a></td>
                              <td>8</td>
                             
                            </tr>


                            <tr>
                              <td><a href="Dashboard1.html">Patient Journey</a></td>
                              <td>35</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Pricing (regular, van Westendorp, GG)</a></td>
                              <td>29</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Purchase Process</a></td>
                              <td>19</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Purchase Considerations</a></td>
                              <td>63</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Purchase Stakeholders</a></td>
                              <td>15</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Reimbursement and Insurance </a></td>
                              <td>5</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">ODI (Outcome Driven Innovation)</a></td>
                              <td>2</td>
                             
                            </tr>

                            <tr>
                              <td><a href="Dashboard1.html">Unmet Needs and Challenges </a></td>
                              <td>46</td>
                             
                            </tr> 


                          </tbody>*/}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="col-lg-5 col-md-3 col-sm-12 col-12">
                <div class="card pieCard">
                  <div class="card-body">
                  <table class='table table-hover' >

                            {/* <table className='table'> */}
                              <thead>
                                  <tr>
                                    <th scope="col" style={{backgroundColor:'#CFF8DF'}}></th>
                                    <th scope="col"  style={{backgroundColor:'#CFF8DF'}}>
                                    Overview of columns per tab/topic 
                                    </th>
                                  
                                    <th scope="col" style={{backgroundColor:'#CFF8DF'}}>
                                    Description 
                                    </th>
                                  </tr>

                              </thead>

                              <tbody>

                                    <tr>
                                      <td><b>A</b></td>
                                      <td>Name Project</td>
                                      <td>Source</td>
                                    </tr>

                            <tr>
                              <td><b>B</b></td>
                              <td>Qual/Quant</td>
                              <td>Type of research</td>
                            </tr>

                            <tr>
                              <td><b>C</b></td>
                              <td>Topic</td>
                              <td>Question topic</td>
                            </tr>

                            <tr>
                              <td><b>D</b></td>
                              <td>Subtopic</td>
                              <td>Division in topic</td>
                            </tr>

                            <tr>
                              <td><b>E</b></td>
                              <td>Category/Industry</td>
                              <td>which type of company  (In relation to Source)</td>
                            </tr>

                            <tr>
                              <td><b>F</b></td>
                              <td>Question</td>
                              <td>Question itself</td>
                            </tr>

                            <tr>
                              <td><b>G</b></td>
                              <td>Question Type</td>
                              <td>Type of question: Open, rating etc..</td>
                            </tr>

                            <tr>
                              <td><b>H</b></td>
                              <td>Instruction</td>
                              <td>For the respondent</td>
                            </tr>

                            <tr>
                              <td><b>I</b></td>
                              <td>Code</td>
                              <td>For certain recurring questions, commonly used answering categories are given</td>
                            </tr>



                              </tbody>
                              

                            </table>
                            <b style={{fontSize:14,padding:4}}>topics that include 'must have' questions; must have questions</b> <b style={{ backgroundColor:'orange',color:'white',fontSize:14,padding:4}}>'orange'</b> <b style={{fontSize:14}}> highlighted</b>
                            <b style={{fontSize:14}}><br/>(source: selection of 2021 projects CHC)</b>


                  </div>
                </div>
              </div>
            </div>
          </div>
</div>

          <footer>
        <div className="footer-top text-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center" style={{height:'20px'}}>
                        {/* <div className="col-auto social-icons">
                            <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                            <a href="https://twitter.com/cetashealthcare" target="_blank"><i className='bx bxl-twitter'></i></a>
                            <a href="https://www.youtube.com/channel/UCzJPjYnPXRZjWs-H1IqvMFQ" target="_blank"><i className='bx bxl-youtube'></i></a>
                        </div>
                        <div className="col-auto conditions-section">
                            <a href="https://www.cetas-healthcare.com/privacy_policy/" target="_blank" rel="opener">Privacy</a>
                            <a href="https://www.cetas-healthcare.com/terms-of-use/" target="_blank" rel="opener">Terms</a>
                            <a href="https://www.cetas-healthcare.com/cookie-notice/" target="_blank" rel="opener">Cookie Notice</a>
                        </div> */}
                        <p className="mb-0">Copyright Cetas 2022. All rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>


            </div>
        );



        
  
        }


        


  }
export default QuestionTopicsList;