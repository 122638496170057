import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
//import ReactDOM  from 'react-dom/client';

import axios, { Axios } from  'axios';
import {
    FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Alert,
    Container, Row, Col
  } from 'reactstrap';

import UserHader from './UserHeader';
import Swal from 'sweetalert2';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
 

class ShowCartitems extends Component{

constructor(props){
    super(props)
    
    this.state = {
        ShowBar:false,
        Redirect:false,
        redirectQuestionTopicsList:false,
        redirectQuestionList:false,
        redirectQuestionTopicsList:false,

        SelectedQuestionlist:[],
        user_id:'',

        fatchedQuestionList:[],
        testDataSpeciality:[],
        arrT1Data:[],
        arrT2Data:[],
        arrT3Data:[],
        arrT4Data:[],
        arrT5Data:[],
        arrT6Data:[],
        arrT7Data:[],
        arrT8Data:[],
        arrT9Data:[],
        arrT10Data:[],
        arrT11Data:[],
        arrT12Data:[],
        arrT13Data:[],
        arrT14Data:[],
        arrT15Data:[],
        arrT16Data:[],
        arrT17Data:[],
        arrT18Data:[],
        

        topics_id:'',
        selected_question_id:'',

        question_code:'GE Healthcare,Siemens,Philips,Canon/Toshiba,Spectrum Dynamics,United Imaging,Samsung,Carestream,Hitachi,Fujifilm,Agfa,Konica Minolta,Nanox,Hologic'
    }

    
   

    
// fetch('http://localhost/booklet/service/User/get_question_Details_withoutcondition', options)
       
//             .then((Response) => Response.json())
//             .then((findresponse) => {
//                 console.log(findresponse)
//                 var testDataSpeciality = findresponse.Selected_list;
//                 console.log("fatchedQuestionList", JSON.stringify(this.state.fatchedQuestionList));
               
//                 this.setState({

//                     fatchedQuestionList:testDataSpeciality,
//                 })



//             //     this.state.SelectedQuestionlist.map((Questionlist,i)=>(
                
//             //     testDataSpeciality.forEach(element => {
                        
//             //         if (element.topics_id == this.state.SelectedQuestionlist[i].topics_id) {
//             //             // alert(element.topics_id)
//             //             // alert(element.topics)

//             //             let { SelectedQuestionlist } = this.state;
//             //             SelectedQuestionlist[i].topics_id = element.topics;
                        
//             //             this.setState({ SelectedQuestionlist });
//             //             // alert( this.state.SelectedQuestionlist[i].topics_id);
                       
//             //         }
//             //     })
                
               

//             //     )
                
//             //    )
                
// //
                
//             })

// //




}

exportHTML(){

 
 var html, link, blob, url, css,header,footer;
 
 // EU A4 use: size: 841.95pt 595.35pt;
 // US Letter use: size:11.0in 8.5in;
 header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' "+
 "xmlns:w='urn:schemas-microsoft-com:office:word' "+
 "xmlns='http://www.w3.org/TR/REC-html40'>"+
 "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";

 footer = "</body></html>";

 css = (
   '<style>' +
   //'@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
  '@page WordSection1{size: A4;mso-page-orientation: portrait;}' +   
   'div.WordSection1 {page: WordSection1;}' +
   // 'table{border-collapse:collapse;}td{border:1px gray solid;padding:4px;width:8em;min-width:8em;font-size: 12px;}th{border:1px gray solid;padding:4px;width:8em;min-width:8em;font-size: 12px;}'+
  'table{border-collapse:collapse;}td{border:1px gray solid;padding:4px;font-size: 12px;}th{border:1px gray solid;padding:4px;font-size: 12px;}'+
   
  '</style>'
 );
 
 //html = window.docx.innerHTML;
 //html=header+document.getElementById("source-html").innerHTML+footer;
 html=document.getElementById("source-html").innerHTML+footer;
 
 blob = new Blob(['\ufeff', css + html], {
   type: 'application/msword'
 });
 url = URL.createObjectURL(blob);
 link = document.createElement('A');
 link.href = url;
 // Set default file name. 
 // Word will append file extension - do not add an extension here.
 link.download = 'QList';   
 document.body.appendChild(link);
 if (navigator.msSaveOrOpenBlob ) navigator.msSaveOrOpenBlob( blob, 'QList.doc'); // IE10-11
     else link.click();  // other browsers
 document.body.removeChild(link);

  this.UpdateStatusAndExportToWord();

  // var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' "+
  //      "xmlns:w='urn:schemas-microsoft-com:office:word' "+
  //      "xmlns='http://www.w3.org/TR/REC-html40'>"+
  //      "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
  // var footer = "</body></html>";
  // //var sourceHTML = header+document.getElementById("source-html").innerHTML+footer;
  // var sourceHTML = header+document.getElementById("source-html").innerHTML+footer;
  
  // var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
  // var fileDownload = document.createElement("a");
  // document.body.appendChild(fileDownload);
  // fileDownload.href = source;
  // fileDownload.download = 'document.doc';
  // fileDownload.click();
  //document.body.removeChild(fileDownload);
}

  


UpdateStatusAndExportToWord = () =>
{
  this.setState({
    ShowBar: true,
    progressBar: true,
  });
    // const url = global.websiteUrl+'/service/Client/create_client';
 // const url=global.websiteUrl+'/booklet/service/User/Update_Export_Question_Status';
 
 const url=global.websiteUrl+'/service/User/Update_Export_Question_Status'; 
 let formData = new FormData();
  // formData.append('user_id', this.state.userInput);
  // formData.append('topics_id', this.state.Client_Unique_id);
  
  formData.append('user_id',this.state.user_id);
  formData.append('status', 'Exported');
  //formData.append('date', this.state.SelectedQuestionList.date);
 
  const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
  }
  axios.post(url, formData, config)
      .then(response => {
          console.log(response);
          var SelectedQuestionList = response.data;
          console.log("clientStatus", JSON.stringify(SelectedQuestionList));

          var clientstatus = response.data.status;
          var message = response.data.message;
          console.log("message", message)
          // this.exportHTML(this);
          //this.exportHTML.bind(this);
          //alert(response.status);
          //alert(response.data.statuscode);
          if (response.status == 200) {
              Swal.fire({
                  icon: 'success',
                  //text: message, 
                  text:'Exported Successfully',                 
                  confirmButtonColor: '#A90007',
                  confirmButtonText: 'OK',                                
                  
              })
            
              //this.state.SelectedQuestionList.selected_question_id='';
           this.setState({
            SelectedQuestionList: {
                 // user_id: '',
                  //topics_id: '',
                  selected_question_id: '',
                  //date: '',
              }
          });

              this.setState({
                // redirectShowCartitems:true
                 redirectQuestionTopicsList:true,
             
             });

                  this.setState({
                      ShowBar: false,
                      progressBar: false,
                    });


          }
          else {
              Swal.fire({
                  icon: 'error',
                  //text: message,
                  text:'unsuccess',
                  confirmButtonColor: '#A90007',
                  confirmButtonText: 'OK'
              })

              
              
            

             
          }
          // this.setState({
          //   SelectedQuestionList: {
          //         user_id: '',
          //         topics_id: '',
          //         selected_question_id: '',
          //         date: '',
          //     }
          // });
         
         
          
          
      })
      .catch(error => {
          console.log(error);
      });
 

}


componentDidMount = async () => {
  
   this.setState({
     ShowBar: true,
     progressBar: true,
   });
  
  let getsession_user_id= await sessionStorage.getItem('user_id');
    if(getsession_user_id !== null)
  {
      await this.setState({

        user_id:getsession_user_id,                
      
      })

      
  }


  //alert(this.state.user_id)
  let options = {
    method:'POST',
    
}


let formData = new FormData();
formData.append('user_id',this.state.user_id);
formData.append('status','New');
formData.append('topics_id',"");

const config={
    // headers: { 'content-type': 'multipart/form-data' }
};
//const url='http://localhost/booklet/service/User/get_selected_question_list';
//const url=global.websiteUrl+'/booklet/service/User/get_selected_question_list';
const url=global.websiteUrl+'/service/User/get_selected_question_list';

axios.post(url,formData,config)
.then( response =>{
    console.log(response);
    var KOL_Personal_Info = response.data.Selected_list;
    console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));
  
    if(response.data.status ==true)
    {
        this.setState({
           // SelectedQuestionlist:response.data.Selected_list,
           SelectedQuestionlist:KOL_Personal_Info,

        })
    }  
   

console.log("SelectedQuestionlist", this.state.SelectedQuestionlist);

// ************** Add Sub Topics ******************
//if (this.state.SelectedQuestionlist.topics_id) {
   // fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
   //fetch('http://localhost/booklet/service/User/get_question_TopicsDetails', options)
   //fetch(global.websiteUrl+'/booklet/service/User/get_question_TopicsDetails', options)
   fetch(global.websiteUrl+'/service/User/get_question_TopicsDetails', options)
  
        .then((Response) => Response.json())
        .then((findresponse) => {
            console.log(findresponse)
           this.state.testDataSpeciality = findresponse.Selected_list;
            console.log("testDataSpeciality", JSON.stringify(this.state.testDataSpeciality));
            
            this.setState({

                testDataSpeciality:findresponse.Selected_list,
            })
           
            
        
       
        console.log("testDataSpeciality", this.state.testDataSpeciality);

//this.state.SelectedQuestionlist.map( (el,i)=> (

this.state.SelectedQuestionlist.forEach(element => {
 
// alert( element.topics_id );
//alert( element.selected_question_id );
//alert( this.state.SelectedQuestionlist[i].selected_question_id );
// var topics_id='T1';
// var selected_question_id="'Q5','Q6','Q1'";
//    this.setState({
//     topics_id:this.state.SelectedQuestionlist[element].topics_id,
//     selected_question_id:this.state.SelectedQuestionlist[element].selected_question_id,
//    })



//if(element.selected_question_id == "'Q5','Q6'" || "'Q5','Q6','Q1'")
if(element.selected_question_id !== "")
{

// let $topics_id='T1';
// let $selected_question_id="'Q5','Q6','Q1'";


let $topics_id=element.topics_id;
let $selected_question_id=element.selected_question_id;

// alert($topics_id);
// alert($selected_question_id);

let formData1 = new FormData();
formData1.append('topics_id',$topics_id);
formData1.append('selected_question_id',$selected_question_id);

//alert($topics_id);
//alert($selected_question_id);

// c    onst config={
//     // headers: { 'content-type': 'multipart/form-data' }
// };
//const url1='http://localhost/booklet/service/User/get_question_Details';
//const url1=global.websiteUrl+'/booklet/service/User/get_question_Details';
const url1=global.websiteUrl+'/service/User/get_question_Details';

axios.post(url1,formData1,config)
.then( response =>{
console.log(response);
 var KOL_Personal_Info = response.data.Selected_list;
 console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));

if(response.data.status ==true)
{
    this.setState({
        fatchedQuestionList:response.data.Selected_list,          
    })

    
}  


console.log("fatchedQuestionList", this.state.fatchedQuestionList);

// // // ************** Add Sub Topics ******************
// // //if (this.state.SelectedQuestionlist.topics_id) {
// //    // fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
// //    fetch('http://localhost/booklet/service/User/get_question_TopicsDetails', options)
   
// //         .then((Response) => Response.json())
// //         .then((findresponse) => {
// //             console.log(findresponse)
// //            let testDataSpeciality = findresponse.Selected_list;
// //             console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
       // alert(this.state.testDataSpeciality[0].topics_id)
       
        this.state.fatchedQuestionList.map((Questionlist,i)=>(                
           this.state.testDataSpeciality.forEach(element => {
            //alert(this.state.testDataSpeciality[0].topics_id);
                if (element.topics_id == this.state.fatchedQuestionList[i].topics_id) {
                   
                    let { fatchedQuestionList } = this.state;
                    fatchedQuestionList[i].topics_id = element.topics;                        
                    this.setState({ fatchedQuestionList });

                    

                    if(element.topics_id =="T1")
                        {
                                this.setState({
                                        
                                    arrT1Data:fatchedQuestionList,
                                        
                                })
                        }

                        if(element.topics_id =="T2")
                        {
                                this.setState({
                                        
                                    arrT2Data:fatchedQuestionList,
                                        
                                })
                        }
                   
                        if(element.topics_id =="T3")
                        {
                                this.setState({
                                        
                                    arrT3Data:fatchedQuestionList,
                                        
                                })
                        }
                        
                        if(element.topics_id =="T4")
                        {
                                this.setState({
                                        
                                    arrT4Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T5")
                        {
                                this.setState({
                                        
                                    arrT5Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T6")
                        {
                                this.setState({
                                        
                                    arrT6Data:fatchedQuestionList,
                                        
                                })
                        }
                        
                        if(element.topics_id =="T7")
                        {
                                this.setState({
                                        
                                    arrT7Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T8")
                        {
                                this.setState({
                                        
                                    arrT8Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T9")
                        {
                                this.setState({
                                        
                                    arrT9Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T10")
                        {
                                this.setState({
                                        
                                    arrT10Data:fatchedQuestionList,
                                        
                                })
                        }
                   
                        
                        if(element.topics_id =="T11")
                        {
                                this.setState({
                                        
                                    arrT11Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T12")
                        {
                                this.setState({
                                        
                                    arrT12Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T13")
                        {
                                this.setState({
                                        
                                    arrT13Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T14")
                        {
                                this.setState({
                                        
                                    arrT14Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T15")
                        {
                                this.setState({
                                        
                                    arrT15Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T16")
                        {
                                this.setState({
                                        
                                    arrT16Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T17")
                        {
                                this.setState({
                                        
                                    arrT17Data:fatchedQuestionList,
                                        
                                })
                        }

                        
                        if(element.topics_id =="T18")
                        {
                                this.setState({
                                        
                                    arrT18Data:fatchedQuestionList,
                                        
                                })
                        }
                        
                }
            })
            )
            

            )



        // //     /// Add sub topics end

            
        // // })

//

//}
}) 


}



})




}) 

})
 

this.setState({
  ShowBar: false,
  progressBar: false,
});

}


BackToQuestionTopicsList(topics_id,topics)
{ 
  sessionStorage.setItem('topics', '');  
  sessionStorage.setItem('topics', topics);

  sessionStorage.setItem('topics_id', '');  
  sessionStorage.setItem('topics_id', topics_id);
  this.setState({

    redirectQuestionList:true,

    });

}

BackToQuestionTopicList()
{ 
  this.setState({

    redirectQuestionTopicsList:true,

    });

}



addTableRow = (result,index) => {
  return (
      <tr >
      <td >{result}</td>
      <td >{index+1}</td>
      {/* <td style={{color:'red', fontWeight:'bold'}}>CONTINUE</td>
      <td ></td>
      */}
    </tr>)
}

createTable = (results) => {
  return (
    <div>
    <table>
      <thead>
        <tr>
          <th >Title</th>
          <th >&nbsp;&nbsp;CODE&nbsp;&nbsp;</th>
          {/* <th >INSTRUCTIONS</th>
          <th >QUOTA</th>
          */}
        </tr>
      </thead>
      <tbody>
        {results.map((result, index) => {
          return this.addTableRow(result,index)
        })}
      </tbody>
    </table>
    </div>
  )
}


render()
{

  if(this.state.redirectQuestionTopicsList)
  {
    return (
      <Redirect to={{
       // pathname:'/ShowCartitems'
        pathname:'/QuestionTopicsList'

      }}/>

    )

  }



  if (this.state.redirectQuestionList)
  {
    return (<Redirect to={{
      pathname: '/QuestionList'
    }} />)
  }

  if (this.state.BackToQuestionTopicsList)
  {
    return (<Redirect to={{
      pathname: '/QuestionTopicsList'
    }} />)
  }
  

    return(
        <div>
        <div>
          {this.state.ShowBar ? <Modal isOpen={true}  centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' style={{ textAlign:'center'}} />
          </Modal> : null}
        </div>
        <UserHader />
<div  className='pg-content'>
        <div class="container-fluid" id="mainDashboard">
        <div class="row mt-3">
          <div class="col-lg-9 col-md-9 col-sm-12 col-12" style={{width:'100%'}}>
            
             <div className="back-section">
                <a onClick={this.BackToQuestionTopicList.bind(this)}>
                  <img src={require('../Assets/Images/back-arrow.svg')} />
                  Back
                </a>
                {/* <Button onClick={this.UpdateStatusAndExportToWord.bind(this)}  >Export to word</Button> */}
                
                {/* <Button onClick={this.exportHTML.bind(this)}  >Export to word</Button> */}
                {/* UpdateStatusAndExportToWord */}
              <Button onClick={this.exportHTML.bind(this)}  >Export to word</Button>
                
                
              </div>             

     
 
<div id="source-html1" className='showcartitem' >

 <div id="docx" style={{width:'100%', textAlign:'center'}}>
  <div class="WordSection1">
    
     <h2 > Question list </h2>

  </div>
</div>

{/* <div style={{ height:'10px'}}> */}
<div >

{
  (this.state.arrT1Data.length>0)?
  (
    <div>
    
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
                <div class="card">
                <a onClick={this.BackToQuestionTopicsList.bind(this,'T1',"Brand Awareness and Usage")} className='edit-link'> Edit</a>
                  <div class="card-body">
                      <h4>Brand Awareness and Usage</h4>
                        {

                      <div class="table-responsive mt-2">
                        {  
                         this.state.arrT1Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td class='grid_colomn_style14' >Name Project</td>     
                                        <td className='grid_colomn_style14'>Qual/Quant </td>
                                        <td className='grid_colomn_style14'>Topic</td>
                                        <td className='grid_colomn_style14'>Sub Topic</td> 
                                        <td className='grid_colomn_style14'>Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }  


                            
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td className='grid_colomn_style14' >{this.state.arrT1Data[i].p_name}</td>
                              <td className='grid_colomn_style14'>{this.state.arrT1Data[i].methodlogy}</td>
                              <td className='grid_colomn_style14'>{this.state.arrT1Data[i].topic}</td>
                              <td className='grid_colomn_style14'>{this.state.arrT1Data[i].sub_topic}</td>
                              <td className='grid_colomn_style14'>{this.state.arrT1Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT1Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT1Data[i].question_type}</td>
                              <td>{this.state.arrT1Data[i].instruction}</td>
                              <td>{this.state.arrT1Data[i].code}</td>
                             */}
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>


                            // this.state.arrT1Data.map((Questionlist,i)=>(
                                
                            //         <div>
                                       
                            //             {/* <h1> {this.state.fatchedQuestionList[i].topics_id}-{this.state.SelectedQuestionlist[i].selected_question_id}   </h1> */}
                            //             <h4> {this.state.arrT1Data[i].question_id}-{this.state.arrT1Data[i].topics_id}- {this.state.arrT1Data[i].topic}- {this.state.arrT1Data[i].p_name}   </h4>
                                        

                            //         </div>

                            //     )
                            // )

                        }


   
</div>
</div>
</div>     

</div>
  ):(<div></div>)

}


{
  (this.state.arrT2Data.length>0)?
  (
    <div>
    

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T2',"Brand Perception/Satisfaction and Switch")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Brand Perception/Satisfaction and Switch</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT2Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT2Data[i].p_name}</td>
                              <td>{this.state.arrT2Data[i].methodlogy}</td>
                              <td>{this.state.arrT2Data[i].topic}</td>
                              <td>{this.state.arrT2Data[i].sub_topic}</td>
                              <td>{this.state.arrT2Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT2Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT2Data[i].question_type}</td>
                              <td>{this.state.arrT2Data[i].instruction}</td>
                              <td>{this.state.arrT2Data[i].code}</td>
                             */}
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
  ):(<div></div>)

}


{/* //************ */}

{
  (this.state.arrT3Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T3',"Campaign Awareness")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Campaign Awareness</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT3Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                        <td className='Question_H'  >Question</td>
                                      {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT3Data[i].p_name}</td>
                              <td>{this.state.arrT3Data[i].methodlogy}</td>
                              <td>{this.state.arrT3Data[i].topic}</td>
                              <td>{this.state.arrT3Data[i].sub_topic}</td>
                              <td>{this.state.arrT3Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT3Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT3Data[i].question_type}</td>
                              <td>{this.state.arrT3Data[i].instruction}</td>
                              <td>{this.state.arrT3Data[i].code}</td>
                             */}
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
  ):(<div></div>)

}



{
  (this.state.arrT4Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T4',"Claim Test")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Claim Test</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT4Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT4Data[i].p_name}</td>
                              <td>{this.state.arrT4Data[i].methodlogy}</td>
                              <td>{this.state.arrT4Data[i].topic}</td>
                              <td>{this.state.arrT4Data[i].sub_topic}</td>
                              <td>{this.state.arrT4Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT4Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT4Data[i].question_type}</td>
                              <td>{this.state.arrT4Data[i].instruction}</td>
                              <td>{this.state.arrT4Data[i].code}</td> */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
  ):(<div></div>)

}


{
  (this.state.arrT5Data.length>0)?
  (
    <div>


<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T5',"Concept Testing/Message Testing/Value Proposition Testing")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Concept Testing/Message Testing/Value Proposition Testing</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT5Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT5Data[i].p_name}</td>
                              <td>{this.state.arrT5Data[i].methodlogy}</td>
                              <td>{this.state.arrT5Data[i].topic}</td>
                              <td>{this.state.arrT5Data[i].sub_topic}</td>
                              <td>{this.state.arrT5Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT5Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT5Data[i].question_type}</td>
                              <td>{this.state.arrT5Data[i].instruction}</td>
                              <td>{this.state.arrT5Data[i].code}</td> */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
  ):(<div></div>)

}

{
  (this.state.arrT6Data.length>0)?
  (
    <div>


<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T6',"Conjoint (including MaxDiff)")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Conjoint (including MaxDiff)</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT6Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT6Data[i].p_name}</td>
                              <td>{this.state.arrT6Data[i].methodlogy}</td>
                              <td>{this.state.arrT6Data[i].topic}</td>
                              <td>{this.state.arrT6Data[i].sub_topic}</td>
                              <td>{this.state.arrT6Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT6Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT6Data[i].question_type}</td>
                              <td>{this.state.arrT6Data[i].instruction}</td>
                              <td>{this.state.arrT6Data[i].code}</td>
                             */}
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
):(<div></div>)
}


{
  (this.state.arrT7Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T7',"Current Situation Testing")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Current Situation Testing</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT7Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT7Data[i].p_name}</td>
                              <td>{this.state.arrT7Data[i].methodlogy}</td>
                              <td>{this.state.arrT7Data[i].topic}</td>
                              <td>{this.state.arrT7Data[i].sub_topic}</td>
                              <td>{this.state.arrT7Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT7Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT7Data[i].question_type}</td>
                              <td>{this.state.arrT7Data[i].instruction}</td>
                              <td>{this.state.arrT7Data[i].code}</td>
                             */}
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>


</div>
):(<div></div>)
}



{
  (this.state.arrT8Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T8',"Future Situation Testing")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Future Situation Testing</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT8Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT8Data[i].p_name}</td>
                              <td>{this.state.arrT8Data[i].methodlogy}</td>
                              <td>{this.state.arrT8Data[i].topic}</td>
                              <td>{this.state.arrT8Data[i].sub_topic}</td>
                              <td>{this.state.arrT8Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT8Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT8Data[i].question_type}</td>
                              <td>{this.state.arrT8Data[i].instruction}</td>
                              <td>{this.state.arrT8Data[i].code}</td>
                             */}
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
):(<div></div>)
}


{
  (this.state.arrT9Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T9',"Message testing")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Message testing</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT9Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT9Data[i].p_name}</td>
                              <td>{this.state.arrT9Data[i].methodlogy}</td>
                              <td>{this.state.arrT9Data[i].topic}</td>
                              <td>{this.state.arrT9Data[i].sub_topic}</td>
                              <td>{this.state.arrT9Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT9Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT9Data[i].question_type}</td>
                              <td>{this.state.arrT9Data[i].instruction}</td>
                              <td>{this.state.arrT9Data[i].code}</td> */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
):(<div></div>)
}


{
  (this.state.arrT10Data.length>0)?
  (
    <div>


<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T10',"Market Sizing")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Market Sizing</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT10Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT10Data[i].p_name}</td>
                              <td>{this.state.arrT10Data[i].methodlogy}</td>
                              <td>{this.state.arrT10Data[i].topic}</td>
                              <td>{this.state.arrT10Data[i].sub_topic}</td>
                              <td>{this.state.arrT10Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT10Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT10Data[i].question_type}</td>
                              <td>{this.state.arrT10Data[i].instruction}</td>
                              <td>{this.state.arrT10Data[i].code}</td> */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>


</div>
):(<div></div>)
}



{
  (this.state.arrT11Data.length>0)?
  (
    <div>


<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T11',"Naming Research")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Naming Research </h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT11Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                        <td className='Question_' >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT11Data[i].p_name}</td>
                              <td>{this.state.arrT11Data[i].methodlogy}</td>
                              <td>{this.state.arrT11Data[i].topic}</td>
                              <td>{this.state.arrT11Data[i].sub_topic}</td>
                              <td>{this.state.arrT11Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT11Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT11Data[i].question_type}</td>
                              <td>{this.state.arrT11Data[i].instruction}</td>
                              <td>{this.state.arrT11Data[i].code}</td> */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>


</div>
):(<div></div>)
}

{
  (this.state.arrT12Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T12',"Naming Research")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Naming Research</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT12Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT12Data[i].p_name}</td>
                              <td>{this.state.arrT12Data[i].methodlogy}</td>
                              <td>{this.state.arrT12Data[i].topic}</td>
                              <td>{this.state.arrT12Data[i].sub_topic}</td>
                              <td>{this.state.arrT12Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT12Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT12Data[i].question_type}</td>
                              <td>{this.state.arrT12Data[i].instruction}</td>
                              <td>{this.state.arrT12Data[i].code}</td> */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
):(<div></div>)
}


{
  (this.state.arrT13Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T13',"Patient Journey")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Patient Journey</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT13Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT13Data[i].p_name}</td>
                              <td>{this.state.arrT13Data[i].methodlogy}</td>
                              <td>{this.state.arrT13Data[i].topic}</td>
                              <td>{this.state.arrT13Data[i].sub_topic}</td>
                              <td>{this.state.arrT13Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT13Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT13Data[i].question_type}</td>
                              <td>{this.state.arrT13Data[i].instruction}</td>
                              <td>{this.state.arrT13Data[i].code}</td>
                             */}
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
):(<div></div>)
}


{
  (this.state.arrT14Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T14',"Pricing (regular, van Westendorp, GG)")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Pricing (regular, van Westendorp, GG)</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT14Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                        <td className='Question_' >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT14Data[i].p_name}</td>
                              <td>{this.state.arrT14Data[i].methodlogy}</td>
                              <td>{this.state.arrT14Data[i].topic}</td>
                              <td>{this.state.arrT14Data[i].sub_topic}</td>
                              <td>{this.state.arrT14Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT14Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT14Data[i].question_type}</td>
                              <td>{this.state.arrT14Data[i].instruction}</td>
                              <td>{this.state.arrT14Data[i].code}</td>
                             */}
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
):(<div></div>)
}


{
  (this.state.arrT15Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T15',"Purchase Process")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Purchase Process</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT15Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT15Data[i].p_name}</td>
                              <td>{this.state.arrT15Data[i].methodlogy}</td>
                              <td>{this.state.arrT15Data[i].topic}</td>
                              <td>{this.state.arrT15Data[i].sub_topic}</td>
                              <td>{this.state.arrT15Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT15Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT15Data[i].question_type}</td>
                              <td>{this.state.arrT15Data[i].instruction}</td>
                              <td>{this.state.arrT15Data[i].code}</td> */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>


</div>
):(<div></div>)
}


{
  (this.state.arrT16Data.length>0)?
  (
    <div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T16',"Purchase Considerations")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Purchase Considerations</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT16Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT16Data[i].p_name}</td>
                              <td>{this.state.arrT16Data[i].methodlogy}</td>
                              <td>{this.state.arrT16Data[i].topic}</td>
                              <td>{this.state.arrT16Data[i].sub_topic}</td>
                              <td>{this.state.arrT16Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT16Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT16Data[i].question_type}</td>
                              <td>{this.state.arrT16Data[i].instruction}</td>
                              <td>{this.state.arrT16Data[i].code}</td>
                             */}
                              
                            </tr>
                          </tbody>
                          </table>
                            </div> 
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>


</div>
):(<div></div>)
}


{
  (this.state.arrT17Data.length>0)?
  (
    <div>


<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T17',"Purchase Stakeholders")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Purchase Stakeholders</h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT17Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                        <td className='Question_' >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT17Data[i].p_name}</td>
                              <td>{this.state.arrT17Data[i].methodlogy}</td>
                              <td>{this.state.arrT17Data[i].topic}</td>
                              <td>{this.state.arrT17Data[i].sub_topic}</td>
                              <td>{this.state.arrT17Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT17Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT17Data[i].question_type}</td>
                              <td>{this.state.arrT17Data[i].instruction}</td>
                              <td>{this.state.arrT17Data[i].code}</td> */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
):(<div></div>)
}


{
  (this.state.arrT18Data.length>0)?
  (
    <div>


<div class="col-lg-12 col-md-12 col-sm-12 col-12"  style={{marginTop:'10px'}}>
     <div class="card">
     <a onClick={this.BackToQuestionTopicsList.bind(this,'T18',"Reimbursement and Insurance")} className='edit-link'> Edit</a>
               
     <div class="card-body">

<h4> Reimbursement and Insurance </h4>
{                       

                        <div class="table-responsive mt-2">
                        {  
                         this.state.arrT18Data.map((el,i) => (
                          <div>
                                                                           
                            {                         
                             (i===0)?
                              (
                                <table class='table table-hover'>                                
                                  <thead>
                                      <tr style={{fontWeight:'bold'}}>
                                        {/* <td >topics_id </td>
                                        <td >question_id</td> */}
                                        <td >Name Project</td>     
                                        <td >Qual/Quant </td>
                                        <td >Topic</td>
                                        <td >Sub Topic</td> 
                                        <td >Category/Industry </td>
                                         <td className='Question_H'  >Question</td>
                                        {/* <td >Question Type</td>
                                        <td >Instruction</td>
                                        <td >Code</td>                                     */}
                                      </tr>
                                    </thead>
                                </table>
                              
                              ):(<div>
        
                                </div>)
                                
                              }         
                            <table class='table table-hover' >
                              
                            <tbody>                     
                            <tr>                            
                              
                              {/* <td>{this.state.arrT1Data[i].question_id}</td> */}

                              <td>{this.state.arrT18Data[i].p_name}</td>
                              <td>{this.state.arrT18Data[i].methodlogy}</td>
                              <td>{this.state.arrT18Data[i].topic}</td>
                              <td>{this.state.arrT18Data[i].sub_topic}</td>
                              <td>{this.state.arrT18Data[i].category}</td>
                              
                              <td className='Question_'>                             
                                {this.state.arrT18Data[i].question}
                                {/* <view>{this.state.contentlist[i].question}</view>  */}
                                
                              </td>

                              {/* <td>{this.state.arrT18Data[i].question_type}</td>
                              <td>{this.state.arrT18Data[i].instruction}</td>
                              <td>{this.state.arrT18Data[i].code}</td> */}
                            
                              
                            </tr>
                          </tbody>
                          </table>
                            </div>
                            )

                            
                          )

                      }

                     
                      </div>

}


</div>
</div>
</div>

</div>
):(<div></div>)

}

</div>


 {
    (this.state.SelectedQuestionlist.length>0)?
    (<div>       
          <div style={{width:'100%',textAlign:'center',marginTop:'50px',marginBottom:'100px'}}>
          {/* <Button onClick={this.UpdateStatusAndExportToWord.bind(this)} className="button_style" >Export to word</Button> */}
          {/* <Button onClick={this.exportHTML.bind(this)} className="button_style" >Export to word</Button>
          */}
                              
                              
          </div>

</div>):(<div className='showCartItem'>
  <p>no data list</p>

</div>)

 }        

</div>


{/* <div class="content-footer">
    <button id="btn-export" onClick={this.exportHTML.bind(this)} className="button_style" >Export to
        word doc</button>
</div> */}

{/* <div id="source-html" style={{visibility:"hidden"}}> */}
<div id="source-html" style={{visibility:"hidden",height:'10px'}} class="WordSection1">


<div id="docx" style={{width:'100%', textAlign:'center', height:'10px'}}>
  <div >
    
     <h2> Question list </h2>

  </div>
</div>

{
  (this.state.arrT1Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Brand Awareness and Usage</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT1Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  {/* <div className='Export_word'><b>Name Project: </b>{this.state.arrT1Data[i].p_name}</div> 
                                  <div className='Export_word'><b>Qual/Quant: </b>{this.state.arrT1Data[i].methodlogy}</div> 
                                  <div className='Export_word'><b>Topic: </b>{this.state.arrT1Data[i].topic}</div> 
                                  <div className='Export_word'><b>Sub Topic: </b>{this.state.arrT1Data[i].sub_topic}</div> 
                                  <div className='Export_word'><b>Category/Industry: </b>{this.state.arrT1Data[i].category}</div>  */}
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT1Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT1Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT1Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT1Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT1Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT1Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}


{
  (this.state.arrT2Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Brand Perception/Satisfaction and Switch</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT2Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT2Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT2Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT2Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT2Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT2Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT2Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}


{/* 3 */}


{
  (this.state.arrT3Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Campaign Awareness</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT3Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT3Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT3Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT3Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT3Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT3Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT3Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 4 */}

{
  (this.state.arrT4Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Claim Test</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT4Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT4Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT4Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT4Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT4Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT4Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT4Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 5 */}

{
  (this.state.arrT5Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4>Concept Testing/Message Testing/Value Proposition Testing</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT5Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT5Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT5Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT5Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT5Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT5Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT5Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 6 */}

{
  (this.state.arrT6Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Conjoint (including MaxDiff)</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT6Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT6Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT6Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT6Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT6Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT6Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT6Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 7 */}

{
  (this.state.arrT7Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Current Situation Testing</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT7Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT7Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT7Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT7Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT7Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT7Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT7Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}


{/* 8 */}

{
  (this.state.arrT8Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Future Situation Testing</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT8Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT8Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT8Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT8Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT8Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT8Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT8Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}


{/* 9 */}

{
  (this.state.arrT9Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Market Sizing</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT9Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT9Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT9Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT9Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT9Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT9Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT9Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 10 */}

{
  (this.state.arrT10Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Naming Research</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT10Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT10Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT10Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT10Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT10Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT10Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT10Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 11 */}

{
  (this.state.arrT11Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Patient Journey</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT11Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT11Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT11Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT11Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT11Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT11Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT11Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 12 */}

{
  (this.state.arrT12Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Pricing (regular, van Westendorp, GG)</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT12Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT12Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT12Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT12Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT12Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT12Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT12Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}


{/* 13 */}

{
  (this.state.arrT13Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Purchase Process</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT13Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT13Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT13Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT13Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT13Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT13Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT13Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 14 */}

{
  (this.state.arrT14Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Purchase Considerations</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT14Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT14Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT14Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT14Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT14Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT14Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT14Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 15 */}

{
  (this.state.arrT15Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Purchase Stakeholders</h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT15Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT15Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT15Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT15Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT15Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT15Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT15Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 16 */}

{
  (this.state.arrT16Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Reimbursement and Insurance </h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT16Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT16Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT16Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT16Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT16Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT16Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT16Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}



{/* 17 */}

{
  (this.state.arrT17Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> ODI (Outcome Driven Innovation) </h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT17Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT17Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT17Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT17Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT17Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT17Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT17Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}


{/* 18 */}

{
  (this.state.arrT18Data.length>0)?
  (
    <div class="WordSection1">   
      
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card1">
                  <div class="card-body1">
                      <h4> Unmet Needs and Challenges  </h4>
                        {

                      <div class="">
                        {  
                         this.state.arrT18Data.map((el,i) => (
                          <div>                                                                           
                            {                         
                            
                              <div class="col-lg-11 col-md-11 col-sm-11 col-11" style={{ marginLeft:'5%'}} >
                                <div className='row'>  
                                  <div className='Export_word' style={{textAlign:'justify',fontSize:'14px'}}><b>Q{i+1}: </b>{this.state.arrT18Data[i].question}<span style={{ color:'red',fontWeight:'bold' }}> [{this.state.arrT18Data[i].question_type}]</span></div> 
                                  <div className='Export_word_instruction' style={{fontStyle:'italic',fontSize:'12px'}}>{this.state.arrT18Data[i].instruction}</div> 
                                 
                                 {/* {                                    
                                    this.createTable(this.state.question_code.split(','))
                                 }
                                  */}
                                  { (this.state.arrT18Data[i].code !="")?
                                      (
                                        <div className="card">
                                          {
                                            this.createTable(this.state.arrT18Data[i].code.split('#'))
                                          }
                                        </div>
                                      ):(<div></div>) 
                                  }
                                
                                  
                                </div> 

                                {/* {
                                  (this.state.arrT18Data.length > i+1)?(<div>
                                    <hr></hr>                               
                                  </div>):(<div></div>)
                                } */}
                                
                              </div>                               
                                
                              }                              
                            </div>
                            )
                           
                          )

                      }
                      </div>
                     
                        }
</div>
</div>
              </div>     

</div>
  ):(<div></div>)

}







</div>
{/* end convert to word */}




</div>
</div>
        </div>
</div>
<footer>
        <div className="footer-top text-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center" style={{height:'20px'}}>
                        {/* <div className="col-auto social-icons">
                            <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                            <a href="https://twitter.com/cetashealthcare" target="_blank"><i className='bx bxl-twitter'></i></a>
                            <a href="https://www.youtube.com/channel/UCzJPjYnPXRZjWs-H1IqvMFQ" target="_blank"><i className='bx bxl-youtube'></i></a>
                        </div>
                        <div className="col-auto conditions-section">
                            <a href="https://www.cetas-healthcare.com/privacy_policy/" target="_blank" rel="opener">Privacy</a>
                            <a href="https://www.cetas-healthcare.com/terms-of-use/" target="_blank" rel="opener">Terms</a>
                            <a href="https://www.cetas-healthcare.com/cookie-notice/" target="_blank" rel="opener">Cookie Notice</a>
                        </div> */}
                        <p className="mb-0">Copyright Cetas 2022. All rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>


)


}



}
export default ShowCartitems;